import { createStyles, WithStyles, withStyles } from '@material-ui/styles'
import { observer } from 'mobx-react'
import * as React from 'react'
import { ThemeType } from 'theme'
import classnames from 'classnames'

export const StyleProps = (theme: ThemeType) =>
  createStyles({
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.black(0.6),
      zIndex: 3000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      width: '36rem',
      height: 'auto',
    },
  })

interface IBannerOverlay {
  children?: React.ReactNode
  className?: string
}

@observer
export class BannerOverlayCls extends React.Component<
  IBannerOverlay & WithStyles<typeof StyleProps>
> {
  render() {
    const { classes } = this.props
    return (
      this.props.children && (
        <div className={classnames(classes.overlay, this.props.className)}>
          <div className={classes.container}>{this.props.children}</div>
        </div>
      )
    )
  }
}

export const BannerOverlay = withStyles(StyleProps, { name: 'BannerOverlay' })(
  BannerOverlayCls
)
