import { WithStyles, withStyles } from '@material-ui/styles'
import { observer } from 'mobx-react'
import * as React from 'react'
import { SliderNavigationButtonStyles } from './sliderNavigation.style'
import classnames from 'classnames'
import { Button } from 'components/common/elements/buttons'
import iconLeft from 'projects/carllutz/icons/navigationLeft.svg'
import iconRight from 'projects/carllutz/icons/navigationRight.svg'
import withHover, {
  HoveredComponentProps,
  PropsWithClassname,
} from 'components/HOC/withHover'

interface ISliderNavigationButtonProps
  extends WithStyles<typeof SliderNavigationButtonStyles> {
  className?: string
  onClick?: () => void
  direction: 'next' | 'previous'
}

@observer
class SliderNavigationButtonCls extends React.Component<
  ISliderNavigationButtonProps & PropsWithClassname & HoveredComponentProps
> {
  render() {
    const { classes, direction } = this.props
    const icon = direction === 'next' ? iconRight : iconLeft
    return (
      <Button
        className={classnames(classes.container, this.props.className, {
          [classes['hovered']]: this.props.isHovered,
        })}
        onClick={this.props.onClick}
      >
        <img
          alt={`${direction} arrow`}
          className={classes['arrow-icon']}
          src={icon}
        />
      </Button>
    )
  }
}

export default withHover(
  withStyles(SliderNavigationButtonStyles, {
    name: 'NavigationButton',
  })(SliderNavigationButtonCls)
)
