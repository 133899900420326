import { withStyles, WithStyles } from '@material-ui/styles'
import { bind } from 'bind-decorator'
import { appConfig, defaultModules, ExtraRoute } from 'config'
import { action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { MatchMediaProvider } from 'mobx-react-matchmedia'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import Preview from 'Preview'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { importFromProject } from 'utils/importHelper'
import { doNotWaitResult } from 'utils/promise'
import { AppStyles } from './App.style'
import { CookiesBanner } from './components/common/IEBanner/CookiesBanner'
import { IEBanner } from './components/common/IEBanner/IEBanner'
import ContentSlider from './components/ContentSlider/ContentSlider'
import { TopBar } from './components/topbar/TopBar'
import { breakpoints } from './stores/breakpointsStore'

type StyleProps = WithStyles<typeof AppStyles>
type AppProps = StyleProps

interface LazyLoadProps {
  loadFunction(): Promise<JSX.Element | null>
}

@observer
class LazyLoad extends React.Component<LazyLoadProps> {
  @observable.ref
  private _loadedComp: null | JSX.Element = null

  constructor(props: LazyLoadProps) {
    super(props)
    doNotWaitResult(this.load())
  }

  async load() {
    const comp = await this.props.loadFunction()
    runInAction(() => {
      this._loadedComp = comp
    })
  }

  render() {
    if (this._loadedComp) {
      return this._loadedComp
    } else {
      return null
    }
  }
}

@observer
class App extends React.Component<AppProps> {
  modules: Map<string, JSX.Element> = observable.map(new Map(), {
    deep: false,
  })

  render() {
    const extraRoutes: JSX.Element[] = []
    const extraRoutesInfo: ExtraRoute[] = appConfig.extraRoutes
    for (const extraRoute of extraRoutesInfo) {
      const route = (
        <Route
          key={extraRoute.path}
          exact
          path={extraRoute.path}
          render={(props) => (
            <LazyLoad
              loadFunction={async () => {
                const Mod: React.ComponentType<RouteComponentProps> | null = await importFromProject(
                  `components/${extraRoute.component}`
                )
                if (Mod) {
                  return <Mod {...props} />
                } else {
                  return null
                }
              }}
            />
          )}
        />
      )
      extraRoutes.push(route)
    }
    let content: JSX.Element | null = null
    content = (
      <>
        <Switch>
          <Route
            exact
            path={`/${appConfig.contentUnitUrl}/:slideUid?/:sectionUid?`}
            render={(props) => (
              <React.Fragment>
                <ContentSlider {...props} />
                <TopBar {...props} />
                <CookiesBanner {...props} />
                <IEBanner {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/intro"
            render={(props) => (
              <ModulesAndComponentsLoader
                moduleName="Intro"
                isModule={false}
                {...props}
              />
            )}
          />
          <Route exact path="/">
            {appConfig.landingPageRoute !== '/' && (
              <Redirect to={appConfig.landingPageRoute} />
            )}
          </Route>
          <Route exact path="/preview" component={Preview} />
          {extraRoutes}
          <Redirect from="*" to={appConfig.notFoundRoute} />
        </Switch>
      </>
    )
    const modules: JSX.Element[] = []
    for (const [, module] of this.modules.entries()) {
      modules.push(module)
    }
    return (
      <React.Fragment>
        {content}
        {modules}
        <MatchMediaProvider breakpoints={breakpoints} />
      </React.Fragment>
    )
  }

  async componentDidMount() {
    this.loadModules().catch((err) => console.error(err))
  }

  async loadModules() {
    const modules = [...defaultModules, ...appConfig.modules]
    for (const moduleName of modules) {
      this.addModule(
        moduleName,
        <ModulesAndComponentsLoader
          moduleName={moduleName}
          isModule={true}
          key={`module_import_${moduleName}`}
        />
      )
    }
  }

  @bind
  @action
  addModule(moduleName: string, module: JSX.Element) {
    if (moduleName && module) {
      this.modules.set(moduleName, module)
    }
  }
}

export default withStyles(AppStyles)(App)
