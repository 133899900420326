import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const IEBannerStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      width: '100%',
      height: 'auto',
      border: `1px solid ${theme.palette.white()}`,
      padding: '0.5rem',
      color: theme.palette.black(),
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    'inner-container': {
      backgroundColor: theme.palette.white(),
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '3rem',
      position: 'relative',
    },
    'close-button-container': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
    },
    'close-button': {
      margin: 0,
      padding: 0,
      background: 'transparent',
    },
    'close-icon': {},
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    'logo-container': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      maxHeight: 100,
      maxWidth: 100,
    },
    title: {
      fontFamily: theme.fonts.primary.sansSerif,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      letterSpacing: '2.8px',
      textTransform: 'uppercase',
      padding: '1.5rem 0',
    },
    text: {
      width: '100%',
      fontSize: '0.85rem',
      lineHeight: 1.6,
      textAlign: 'center',
    },
    'text-german': {
      fontFamily: theme.fonts.primary.serif,
      paddingBottom: '1rem',
    },
    'divider-container': {
      width: '60%',
    },
    'text-english': {
      fontFamily: theme.fonts.primary.sansSerif,
      fontStyle: 'italic',
    },
    'button-hover-container': {
      position: 'relative',
    },
  })
