import { WithStyles, withStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { currentStory } from 'stores/currentStoryStore'
import { sliderStore } from 'stores/sliderStore'
import { topBarStore } from 'stores/topBarStore'
import { TopBarStyles } from './topBar.style'
import TopBarButtons from './TopBarButtons'

interface MatchParams {
  slideUid: string
  navExtraId?: string
}

interface ITopBarProps
  extends RouteComponentProps<MatchParams>,
    WithStyles<typeof TopBarStyles> {}

export interface ITopBarTab {
  name: string
  icon: JSX.Element
  to: string
}

@observer
class TopBarCls extends React.Component<ITopBarProps> {
  componentWillUnmount() {
    topBarStore.hideTabs()
  }

  render() {
    const { classes } = this.props
    return (
      sliderStore.showContent && (
        <div
          className={classnames(classes.container, {
            [classes.invisible]:
              currentStory.vertiefungExpanded || !topBarStore.topBarVisible,
          })}
        >
          {!currentStory.vertiefungExpanded && (
            <div
              className={classnames(classes['top-dark-overlay'], {
                [classes['narrow-overlay']]: currentStory.sideBarIsShown,
              })}
            />
          )}
          <ModulesAndComponentsLoader isModule={false} moduleName="Logo" />
          <div className={classes['button-placeholder']} />
          <ModulesAndComponentsLoader
            moduleName="TabsContainer"
            isModule={false}
          />
          <TopBarButtons />
        </div>
      )
    )
  }
}

export const TopBar = withStyles(TopBarStyles, { name: 'TopBar' })(TopBarCls)
