import bind from 'bind-decorator'
import { action, observable } from 'mobx'
import getUserLocale from 'get-user-locale'
import { ILanguageType } from 'config'
import {
  clearLocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/localStorage'

class LanguageStore {
  languages: ILanguageType[] = []

  @observable
  language: string = ''

  @bind
  loadLanguages(languages: ILanguageType[]) {
    if (languages) {
      this.languages = languages
    }
    this._handleProjectLocalStorage()
    const fromLocalStorage = getLocalStorageItem<string>('language')
    if (fromLocalStorage) {
      const safeLanguage = this._safelyCropLanguageString(fromLocalStorage)
      this.selectLanguage(safeLanguage)
      return
    }
    const locales = getUserLocale()
    let userLanguage: string | undefined = ''
    if (typeof locales === 'string') {
      userLanguage = this.findSuitableLanguage(locales)
    }
    if (Array.isArray(locales)) {
      const matchingLocales = locales
        .map((lang) => this.findSuitableLanguage(lang))
        .filter((lang) => lang !== undefined)
      userLanguage = matchingLocales.length ? matchingLocales[0] : undefined
    }
    if (userLanguage) {
      this.selectLanguage(userLanguage)
      return
    }
    if (!userLanguage) {
      this.selectLanguage(languages[0].code)
    }
  }

  @bind
  _handleProjectLocalStorage() {
    const devMode =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    if (!devMode) {
      return
    }
    const projectFromStorage = getLocalStorageItem('project')
    if (
      projectFromStorage &&
      projectFromStorage !== process.env.REACT_APP_PROJECT_NAME
    ) {
      clearLocalStorageItem('language')
    }
    setLocalStorageItem({ project: process.env.REACT_APP_PROJECT_NAME })
  }

  @bind
  findSuitableLanguage(lang: string) {
    const safeLang = lang.toLowerCase()
    // exact match
    if (
      this.languages.find(
        (language: ILanguageType) => language.code === safeLang
      )
    ) {
      return safeLang
    }
    const langPrefix = safeLang.split('-')[0]
    // Normally locale has shape lang-country(de-ch), try to find if language specific
    // part is contained in one of the keys of languages
    for (const language of this.languages) {
      if (language.code.includes(langPrefix)) {
        return language.code
      }
    }
  }

  @bind
  @action
  selectLanguage(language: string) {
    if (language === this.language) {
      return
    }
    this.language = language
    window.document.documentElement.lang = language
    this.saveToLocalStorage()
  }

  @bind
  saveToLocalStorage() {
    setLocalStorageItem({ language: this.language })
  }

  @bind
  _safelyCropLanguageString(languageString: string) {
    // Bug: language was sometimes saved in the form '"\"\\\"en-us\\\"\""'
    const match = /(\w+-\w+)/gi.exec(languageString)
    return match?.[1] ?? languageString
  }
}

export const languageStore = new LanguageStore()
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.languageStore = languageStore
