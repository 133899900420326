import { WithStyles, withStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { appConfig } from 'config'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import german from 'hyphenation.de'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Hypher from 'hypher'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { breakpoints } from 'stores/breakpointsStore'
import { chaptersBarStore } from 'stores/chaptersBarStore'
import { contentStore } from 'stores/contentStore/contentStore'
import { menuStore } from 'stores/menuStore'
import { sliderStore } from 'stores/sliderStore'
import { cropChapterNumber } from 'utils/string'
import { safeHtml } from 'utils/validation'
import { ChaptersBarStyles } from './chaptersBar.style'

const h = new Hypher(german)

interface IProps extends WithStyles<typeof ChaptersBarStyles> {
  open: boolean
}

@observer
class ChaptersBarCls extends React.Component<IProps> {
  @bind
  selectChapter(index: number) {
    return () => {
      sliderStore.setCurrentSlideIdx(index)
      menuStore.closeMenu()
    }
  }

  render() {
    const bars = []
    const { classes } = this.props
    for (const [index, chapter] of contentStore.chapters.entries()) {
      if (chapter.id === 'last-slide') {
        continue
      }
      const isActive: boolean = sliderStore.currentSlideIdx === index || false
      let text: JSX.Element | null = null
      text = (
        <div
          className={classnames(
            classes.text,
            isActive ? classes['active-text'] : null
          )}
        >
          <div className={classes['chapter-idx']}>
            <span className={classes['chapter-idx-zero']}>0</span>
            {`${chapter.idx} `}
          </div>

          <div
            className={classes['title-text']}
            {...safeHtml(h.hyphenateText(cropChapterNumber(chapter.title)))}
          ></div>
        </div>
      )
      let chapterTitle: JSX.Element | null = null
      chapterTitle = (
        <React.Fragment>
          {breakpoints.desktop && (
            <React.Fragment>
              <div
                className={classnames(classes['bar-chapter-number'], {
                  [classes['bar-chapter-number-styles']]: menuStore.open,
                })}
                style={{
                  color: isActive ? chapter.color : '',
                }}
              >
                {`0${chapter.idx}`}
              </div>
              <div
                className={classnames(classes.bar)}
                style={{
                  backgroundColor: isActive ? chapter.color : '',
                }}
              />
            </React.Fragment>
          )}
          {text}
        </React.Fragment>
      )
      bars.push(
        <Link
          to={`/${appConfig.contentUnitUrl}/${chapter.uid}`}
          key={chapter.id}
          className={classnames(
            classes['bar-item'],
            {
              [classes['only-show-current-chapter']]:
                chaptersBarStore.onlyShowCurrentChapter && !isActive,
            },
            {
              [classes['active-bar-item']]: isActive,
            }
          )}
          onClick={this.selectChapter(index)}
        >
          {chapterTitle}
        </Link>
      )
    }

    return (
      <div
        className={classnames(
          classes['chapter-bar'],
          menuStore.showChaptersBar || this.props.open
            ? classes.open
            : classes.closed
        )}
      >
        {breakpoints.phone ? bars[sliderStore.currentSlideIdx] : bars}
      </div>
    )
  }
}

export const ChaptersBar = withStyles(ChaptersBarStyles, {
  name: 'ChaptersBar',
})(ChaptersBarCls)
