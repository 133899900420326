import { observable } from 'mobx'

export interface IBreakpoints {
  phone: string | boolean
  desktop: string | boolean
}

export const breakpoints: IBreakpoints = observable({
  phone: '(max-width: 768px) , (max-height: 480px)',
  desktop: '(min-width: 769px) AND (min-height: 480px))',
})
