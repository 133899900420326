import { createStyles, withStyles, WithStyles } from '@material-ui/styles'
import { bind } from 'bind-decorator'
import classnames from 'classnames'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import withHover, {
  HoveredComponentProps,
  PropsWithClassname,
} from 'components/HOC/withHover'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { menuStore } from 'stores/menuStore'
import { importFromProject } from 'utils/importHelper'
import { breakpoints } from 'stores/breakpointsStore'
import { ThemeType } from 'theme'
import { DESKTOP, MOBILE } from 'utils/styles'
import arrowDownIcon from 'components/icons/arrowDown.svg'
import arrowLeftIcon from 'components/icons/arrowLeft.svg'
import arrowLeftDarkIcon from 'components/icons/arrowLeftDark.svg'
import arrowRightIcon from 'components/icons/arrowRight.svg'
import arrowRightDarkIcon from 'components/icons/arrowRightDark.svg'
import closeIcon from 'components/icons/cross.svg'
import closeIconBlack from 'components/icons/crossBlack.svg'
import closeIconDark from 'components/icons/crossDark.svg'
import menuIcon from 'components/icons/menu.svg'
import menuCloseIcon from 'components/icons/menuClose.svg'
import arrowDownAnimatedIcon from 'components/icons/scrollingArrow.svg'

export interface ButtonProp extends React.HTMLAttributes<HTMLAnchorElement> {
  to?: string
  onClick?: (e: React.MouseEvent) => void
  children?: React.ReactNode
  isExternal?: boolean
  download?: boolean
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  className?: string
  style?: React.CSSProperties
}

export const ButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      fontFamily: theme.fonts.primary.sansSerif,
    },
  })

@observer
class ButtonCls extends React.Component<
  ButtonProp & WithStyles<typeof ButtonStyles>
> {
  render() {
    const { classes } = this.props
    const clazznames = Object.values(classes)
    const s = classnames(clazznames, this.props.className)
    if (this.props.to) {
      if (this.props.isExternal) {
        return (
          <a
            href={this.props.to}
            rel="noopener noreferrer"
            target="_blank"
            download={this.props.download}
            className={s}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            style={this.props.style}
          >
            {' '}
            {this.props.children}{' '}
          </a>
        )
      }
      return (
        <Link
          to={this.props.to}
          className={s}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          style={this.props.style}
        >
          {' '}
          {this.props.children}{' '}
        </Link>
      )
    } else {
      return (
        <div
          className={s}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          style={this.props.style}
        >
          {' '}
          {this.props.children}{' '}
        </div>
      )
    }
  }
}

export const Button = withStyles(ButtonStyles, { name: 'Button' })(ButtonCls)

export class StartButton extends React.Component<ButtonProp> {
  render() {
    return (
      <Button {...this.props}>
        {this.props.children}
        <div>Start</div>
      </Button>
    )
  }
}

export const MenuButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      height: theme.constants.sizes.desktop.bottomBarHeight,
      width: theme.constants.sizes.desktop.bottomBarHeight,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [`@media ${DESKTOP}`]: {
        backgroundSize: '50px 50px',
        '&:hover': {
          backgroundSize: '54px 54px',
        },
      },
      [`@media ${MOBILE}`]: {
        height: theme.constants.sizes.phone.topBarHeight,
        width: theme.constants.sizes.phone.topBarHeight,
        backgroundSize: '35px 35px',
      },
    },
  })

@observer
class MenuButtonCls extends React.Component<
  ButtonProp & WithStyles<typeof MenuButtonStyles>
> {
  @observable.ref
  button: JSX.Element | null = null

  @observable.ref
  closeButton: JSX.Element | null = null

  async componentDidMount() {
    this.loadMenuButton().catch((err) => console.error(err))
  }

  @bind
  @action
  setButton(button: JSX.Element | null) {
    this.button = button
  }

  @bind
  @action
  setCloseButton(button: JSX.Element | null) {
    this.closeButton = button
  }

  @bind
  @action
  async loadMenuButton() {
    try {
      const shouldThrowModuleLoadingError = true
      const MenuButton: React.ComponentType | null = await importFromProject(
        'components/buttons/Menu/index.tsx',
        shouldThrowModuleLoadingError
      )
      if (MenuButton) {
        this.setButton(<MenuButton />)
      }
    } catch (err) {
      const icon = await this.loadMenuIcon()
      if (icon) {
        this.setButton(
          <Button
            onClick={menuStore.openMenu}
            style={{
              backgroundImage: `url(${icon})`,
            }}
            {...this.props}
          />
        )
      }
      this.setCloseButton(<MenuCloseButton />)
    }
  }

  @bind
  @action
  async loadMenuIcon() {
    try {
      const shouldThrowModuleLoadingError = true
      const icon: string | null = await importFromProject(
        'icons/menu.svg',
        shouldThrowModuleLoadingError
      )
      return icon
    } catch (err) {
      return menuIcon
    }
  }

  render() {
    return menuStore.open && this.closeButton ? this.closeButton : this.button
  }
}

export const MenuButton = withStyles(MenuButtonStyles, { name: 'MenuButton' })(
  MenuButtonCls
)

export const MenuCloseButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      height: theme.constants.sizes.desktop.bottomBarHeight,
      width: theme.constants.sizes.desktop.bottomBarHeight,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [`@media ${DESKTOP}`]: {
        backgroundSize: '50px 50px',
        '&:hover': {
          backgroundSize: '54px 54px',
        },
      },
      [`@media ${MOBILE}`]: {
        height: theme.constants.sizes.phone.topBarHeight,
        width: theme.constants.sizes.phone.topBarHeight,
        backgroundSize: '35px 35px',
      },
    },
  })

@observer
class MenuCloseButtonCls extends React.Component<
  ButtonProp & WithStyles<typeof MenuCloseButtonStyles>
> {
  @observable
  icon: string = ''

  async componentDidMount() {
    const icon = await this.loadMenuIcon()
    if (icon) {
      this.setIcon(icon)
    }
  }

  @bind
  @action
  setIcon(icon: string) {
    this.icon = icon
  }

  @bind
  @action
  async loadMenuIcon() {
    try {
      const shouldThrowModuleLoadingError = true
      const icon: string | null = await importFromProject(
        'icons/menuClose.svg',
        shouldThrowModuleLoadingError
      )
      return icon
    } catch (err) {
      return menuCloseIcon
    }
  }

  render() {
    return (
      <Button
        onClick={menuStore.closeMenu}
        {...this.props}
        style={{
          backgroundImage: `url(${this.icon})`,
        }}
      ></Button>
    )
  }
}

export const MenuCloseButton = withStyles(MenuCloseButtonStyles, {
  name: 'MenuCloseButton',
})(MenuCloseButtonCls)

const CrossButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      height: theme.constants.sizes.desktop.topBarHeight,
      width: theme.constants.sizes.desktop.topBarHeight,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0px',
      right: '0px',
      padding: '10px',
      cursor: 'pointer',
      zIndex: 2600,
      [`@media ${MOBILE}`]: {
        width: '36px',
        height: '36px',
      },
    },
    icon: {
      width: '35%',
      [`@media ${MOBILE}`]: {
        width: '80%',
      },
    },
  })

interface ICrossButtonProps extends ButtonProp {
  mode?: 'dark' | 'light' | 'black'
}

@observer
class CrossButtonCls extends React.Component<
  ICrossButtonProps & WithStyles<typeof CrossButtonStyles>
> {
  @observable
  fullscreenCloseButton: JSX.Element | null = null

  componentDidMount() {
    this.loadButton().catch((err) => console.error(err))
  }

  async loadButton() {
    let button: JSX.Element | null = null
    const { classes } = this.props
    const modesIconsMap = {
      dark: closeIconDark,
      light: closeIcon,
      black: closeIconBlack,
    }
    const iconSrc = this.props.mode
      ? modesIconsMap[this.props.mode]
      : breakpoints.phone
      ? closeIconDark
      : closeIcon
    button = (
      <Button {...this.props} classes={{ button: classes.button }}>
        <img src={iconSrc} className={classes.icon} alt="Close icon" />
      </Button>
    )
    const ProjectButton: React.ComponentType | null = await importFromProject(
      'components/buttons/CloseSubcontent/index.tsx'
    )
    if (ProjectButton) {
      button = <ProjectButton {...this.props} />
    }
    this.setCloseButton(button)
  }

  @bind
  @action
  setCloseButton(button: JSX.Element) {
    this.fullscreenCloseButton = button
  }

  render() {
    return this.fullscreenCloseButton
  }
}

export const CrossButton = withStyles(CrossButtonStyles, {
  name: 'CrossButton',
})(CrossButtonCls)

export const LeftButtonStyles = (_theme: ThemeType) =>
  createStyles({
    arrow: {
      width: '60%',
      height: '60%',
      zIndex: 1,
      [`@media ${MOBILE}`]: {
        width: '80%',
        height: '80%',
      },
    },
    button: {
      height: '30px',
      width: '30px',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.1)',
      },
      [`@media ${MOBILE}`]: {
        width: 'auto',
      },
    },
  })

interface LeftButtonProps extends ButtonProp {
  useDark?: boolean
}

class LeftButtonCls extends React.Component<
  LeftButtonProps & WithStyles<typeof LeftButtonStyles>
> {
  render() {
    const { classes, useDark } = this.props
    const leftButton = (
      <img
        src={useDark ? arrowLeftDarkIcon : arrowLeftIcon}
        className={classes.arrow}
        alt="Arrow left icon"
      />
    )
    return (
      <Button {...this.props} classes={{ button: classes.button }}>
        {leftButton}
      </Button>
    )
  }
}

export const LeftButton = withStyles(LeftButtonStyles, { name: 'LeftButton' })(
  LeftButtonCls
)

export const RightButtonStyles = (_theme: ThemeType) =>
  createStyles({
    arrow: {
      width: '60%',
      height: '60%',
      [`@media ${MOBILE}`]: {
        width: '80%',
        height: '80%',
      },
    },
    button: {
      height: '30px',
      width: '30px',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.1)',
      },
      [`@media ${MOBILE}`]: {
        width: 'auto',
      },
    },
  })

interface RightButtonProps extends ButtonProp {
  useDark?: boolean
}

class RightButtonCls extends React.Component<
  RightButtonProps & WithStyles<typeof RightButtonStyles>
> {
  render() {
    const { classes, useDark } = this.props
    const rightButton = (
      <img
        src={useDark ? arrowRightDarkIcon : arrowRightIcon}
        className={classes.arrow}
        alt="Arrow right icon"
      />
    )
    return (
      <Button {...this.props} classes={{ button: classes.button }}>
        {rightButton}
      </Button>
    )
  }
}

export const RightButton = withStyles(RightButtonStyles, {
  name: 'RightButton',
})(RightButtonCls)

export const SliderButtonStyles = (theme: ThemeType) =>
  createStyles({
    square: {
      width: 6,
      height: 6,
      backgroundColor: theme.palette.white(0.7),
    },
    button: {
      margin: '0 1rem',
      cursor: 'pointer',
      [`@media ${MOBILE}`]: {
        margin: 0,
      },
    },
    'no-margins': {
      margin: 0,
    },
    scaled: {
      [`@media ${DESKTOP}`]: {
        transform: 'scale(1.8)',
      },
    },
    active: {
      backgroundColor: theme.palette.white(1.0),
    },
  })

interface SliderButtonProps extends ButtonProp {
  isActive: boolean
  size?: number
  activeColor?: string
  mainColor?: string
  isNarrow?: boolean
  activeClassName?: string
  hoverClassName?: string
}

@observer
class SliderButtonCls extends React.Component<
  SliderButtonProps & WithStyles<typeof SliderButtonStyles>
> {
  @observable
  isHovered: boolean = false

  @bind
  @action
  activateHover() {
    this.isHovered = true
  }

  @bind
  @action
  disactivateHover() {
    this.isHovered = false
  }

  render() {
    const { classes, isActive } = this.props
    const getBackgroundColor = () => {
      const { isActive, activeColor, mainColor } = this.props
      if (isActive) {
        if (activeColor) {
          return activeColor
        }
      } else {
        if (mainColor) {
          return mainColor
        }
      }
      return ''
    }
    const activeClassName = this.props.activeClassName ?? classes.active
    const hoverClassName = this.props.hoverClassName ?? classes.scaled
    const squareButton = (
      <div
        className={classnames(
          classes.square,
          { [hoverClassName]: isActive || this.isHovered },
          { [activeClassName]: isActive }
        )}
        style={{
          backgroundColor: getBackgroundColor(),
          width: this.props.size ?? '',
          height: this.props.size ?? '',
        }}
      ></div>
    )
    return (
      <Button
        {...this.props}
        onMouseEnter={this.activateHover}
        onMouseLeave={this.disactivateHover}
        classes={{ button: classes.button }}
        className={classnames({ [classes['no-margins']]: this.props.isNarrow })}
      >
        {squareButton}
      </Button>
    )
  }
}

export const SliderButton = withStyles(SliderButtonStyles, {
  name: 'SliderButton',
})(SliderButtonCls)

export const WorksheetButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      padding: '0.7rem  0',
      fontSize: '0.7rem',
      fontWeight: 500,
      lineHeight: 1.33,
      letterSpacing: '1.68px',
      textAlign: 'center',
      textTransform: 'uppercase',
      margin: '0.3rem 0',
      width: '7rem',
      boxShadow: `0 3px 6px 0 ${theme.palette.black(0.16)}`,
    },
  })

type WorksheetButtonProps = ButtonProp

@observer
class WorksheetButtonCls extends React.Component<
  WorksheetButtonProps & WithStyles<typeof WorksheetButtonStyles>
> {
  render() {
    return <Button {...this.props}></Button>
  }
}

export const WorksheetButton = withStyles(WorksheetButtonStyles, {
  name: 'WorksheetButton',
})(WorksheetButtonCls)

export const ShowMoreButtonStyles = (theme: ThemeType) =>
  createStyles({
    'button-show-sections': {
      height: '100%',
      width: '100%',
    },
    'more-button': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    'close-button': {
      transform: 'rotate(180deg)',
    },
    'more-button-dot': {
      width: '2px',
      height: '2px',
      marginBottom: '3px',
    },
    light: {
      backgroundColor: theme.palette.white(1.0),
    },
    dark: {
      backgroundColor: theme.palette.black(0.9),
    },
    icon: {
      width: '25%',
    },
  })

interface ShowMoreButtonProps extends ButtonProp {
  colorScheme?: 'dark' | 'light'
  open?: boolean
  glossary?: boolean
}

export class ShowMoreButtonCls extends React.Component<
  ShowMoreButtonProps & WithStyles<typeof ShowMoreButtonStyles>
> {
  render() {
    const { classes } = this.props
    const moreButton = (
      <div className={classes['more-button']}>
        {!this.props.glossary ? (
          <img src={arrowDownIcon} alt="Close icon" className={classes.icon} />
        ) : (
          <React.Fragment>
            <div
              className={classnames(
                classes['more-button-dot'],
                { [classes.light]: this.props.colorScheme === 'light' },
                { [classes.dark]: this.props.colorScheme === 'dark' }
              )}
            />
            <div
              className={classnames(
                classes['more-button-dot'],
                { [classes.light]: this.props.colorScheme === 'light' },
                { [classes.dark]: this.props.colorScheme === 'dark' }
              )}
            />
            <div
              className={classnames(
                classes['more-button-dot'],
                { [classes.light]: this.props.colorScheme === 'light' },
                { [classes.dark]: this.props.colorScheme === 'dark' }
              )}
            />
          </React.Fragment>
        )}
      </div>
    )
    const closeButton = (
      <div
        className={classnames(classes['more-button'], classes['close-button'])}
      >
        <img src={arrowDownIcon} alt="Close icon" className={classes.icon} />
      </div>
    )
    return (
      <Button
        {...this.props}
        classes={{ button: classes['button-show-sections'] }}
      >
        {this.props.open ? closeButton : moreButton}
      </Button>
    )
  }
}

export const ShowMoreButton = withStyles(ShowMoreButtonStyles, {
  name: 'ShowMoreButton',
})(ShowMoreButtonCls)

export const ArrowBottomButtonStyles = (_theme: ThemeType) =>
  createStyles({
    icon: {
      width: '50%',
    },
  })

export class ArrowBottomButtonCls extends React.Component<
  ButtonProp & WithStyles<typeof ArrowBottomButtonStyles>
> {
  render() {
    const { classes } = this.props
    return (
      <Button {...this.props} classes={{}}>
        <img
          className={classes.icon}
          src={arrowDownAnimatedIcon}
          alt="Arrow down icon"
        />
      </Button>
    )
  }
}

export const ArrowBottomButton = withStyles(ArrowBottomButtonStyles, {
  name: 'ArrowBottomButton',
})(ArrowBottomButtonCls)

export const StandardButtonStyles = (theme: ThemeType) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.cover.desktop.accentColor(),
      boxShadow: `0 3px 6px 0 ${theme.palette.black(0.4)}`,
      padding: '0.8rem 2rem',
      alignSelf: 'center',
      color: theme.palette.white(),
      fontSize: '0.95rem',
      fontWeight: 500,
      lineHeight: 1.33,
      letterSpacing: 1.68,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      [`@media ${MOBILE}`]: {
        fontSize: '0.7rem',
      },
      '&:hover': {},
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.white(0.1),
    },
  })

interface IStandardButtonProps
  extends ButtonProp,
    WithStyles<typeof StandardButtonStyles> {
  hoverOverlayClassName?: string
}

@observer
export class StandardButtonCls extends React.Component<
  IStandardButtonProps & PropsWithClassname & HoveredComponentProps
> {
  render() {
    const { classes, isHovered } = this.props
    return (
      <Button {...this.props} classes={{ button: classes.button }}>
        {this.props.children}
        {isHovered && (
          <div
            className={classnames(
              this.props.hoverOverlayClassName
                ? this.props.hoverOverlayClassName
                : classes.overlay
            )}
          />
        )}
      </Button>
    )
  }
}

export const StandardButton = withHover(
  withStyles(StandardButtonStyles, { name: 'StandardButton' })(
    StandardButtonCls
  )
)

export const StandardSpecialButtonStyles = (theme: ThemeType) =>
  createStyles({
    special: {
      backgroundColor: theme.palette.cover.desktop.accentColor(),
      boxShadow: `0 3px 6px 0 ${theme.palette.black(0.4)}`,
      padding: '0.8rem 2rem',
      alignSelf: 'center',
      color: theme.palette.white(),
      fontSize: '0.95rem',
      fontWeight: 500,
      lineHeight: 1.33,
      letterSpacing: 1.68,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
      [`@media ${MOBILE}`]: {
        fontSize: '0.7rem',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: `2px solid ${theme.palette.cover.desktop.accentColor()}`,
        transition: 'all 0.3s',
      },
    },
    hovered: {
      '&::after': {
        top: '-0.5rem',
        left: '-0.5rem',
        right: '-0.5rem',
        bottom: '-0.5rem',
        border: `2px solid ${theme.palette.cover.desktop.accentColor()}`,
        transition: 'all 0.3s',
      },
    },
  })

interface IStandardSpecialButtonProps extends ButtonProp {
  className?: string
}

export class StandardSpecialButtonCls extends React.Component<
  IStandardSpecialButtonProps &
    PropsWithClassname &
    WithStyles<typeof StandardSpecialButtonStyles> &
    HoveredComponentProps
> {
  render() {
    const { classes, isHovered } = this.props
    return (
      <Button
        {...this.props}
        classes={{
          button: classes.special + ' ' + (this.props.className ?? ''),
        }}
        className={classnames({ [classes.hovered]: isHovered })}
      ></Button>
    )
  }
}

export const StandardSpecialButton = withHover(
  withStyles(StandardSpecialButtonStyles, { name: 'StandardSpecialButton' })(
    StandardSpecialButtonCls
  )
)
