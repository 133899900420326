import { createStyles, WithStyles, withStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { ThemeType } from 'theme'
import { importFromProject } from 'utils/importHelper'
import loadingLogo from './spinner.svg'

export const SpinnerStyles = (_theme: ThemeType) =>
  createStyles({
    container: {},
  })

export interface ISpinnerProps extends WithStyles<typeof SpinnerStyles> {
  className?: string
  isLoadingScreen?: boolean
}

@observer
class LoadingSpinner extends React.Component<ISpinnerProps> {
  @observable
  loadingSpinner: React.ComponentType | null = null
  @observable
  loadingScreen: React.ComponentType | null = null
  @observable
  displayedSpinner: JSX.Element | null = null

  async componentDidMount() {
    await this.loadSpinner()
  }

  async loadSpinner() {
    const spinnerModule = (
      <img
        src={loadingLogo}
        alt="loading logo"
        style={{
          width: '10%',
          height: '10%',
          background: '#212121',
          zIndex: 2000,
        }}
      />
    )
    let Component: React.ComponentType | null = null
    // Difference: Loading screen can have a more complex functionality and be used as an App loader
    // Loading spinner is nothing more than a little animated spinner that can be easily reused in place of loading images etc.

    Component = await importFromProject('components/LoadingScreen')
    if (Component) {
      this.setLoadingScreenComponent(Component)
    }
    Component = await importFromProject('components/LoadingSpinner')
    if (Component) {
      this.setLoadingSpinnerComponent(Component)
    }

    this.selectSuitableComponent()

    if (!this.displayedSpinner) {
      this.setDisplayedSpinner(spinnerModule)
    }
  }

  async componentDidUpdate(prevProps: ISpinnerProps) {
    if (prevProps.isLoadingScreen !== this.props.isLoadingScreen) {
      this.selectSuitableComponent()
    }
  }

  @bind
  selectSuitableComponent() {
    if (this.props.isLoadingScreen && this.loadingScreen) {
      this.setDisplayedSpinner(<this.loadingScreen />)
    } else if (!this.props.isLoadingScreen && this.loadingSpinner) {
      this.setDisplayedSpinner(<this.loadingSpinner />)
    }
  }

  @bind
  @action
  setLoadingScreenComponent(Component: React.ComponentType | null) {
    this.loadingScreen = Component
  }

  @bind
  @action
  setLoadingSpinnerComponent(Component: React.ComponentType | null) {
    this.loadingSpinner = Component
  }

  @bind
  @action
  setDisplayedSpinner(element: JSX.Element) {
    this.displayedSpinner = element
  }

  render() {
    return this.displayedSpinner
  }
}

export default withStyles(SpinnerStyles, { name: 'LoadingSpinner' })(
  LoadingSpinner
)
