import { WithStyles, withStyles } from '@material-ui/styles'
import React from 'react'
import { InlineSubcontentDividerStyles } from './inlineSubcontentDivider.style'
import classnames from 'classnames'

interface ISubcontentDividerProps
  extends WithStyles<typeof InlineSubcontentDividerStyles> {
  type: 'Line' | 'White space'
  color?: 'dark' | 'light' | 'white'
  opacity?: number // Left for reference, not needed !TODO
}

class InlineSubcontentDivider extends React.Component<ISubcontentDividerProps> {
  render() {
    const { type, classes, color } = this.props
    return (
      <div className={classes.container}>
        {type === 'Line' && (
          <React.Fragment>
            <div
              className={classnames(
                classes['divider-left'],
                { [classes['line-light']]: color && color === 'light' },
                { [classes['line-dark']]: color && color === 'dark' }
              )}
            ></div>
            <div
              className={classnames(
                classes['divider-middle'],
                { [classes.light]: color && color === 'light' },
                { [classes.dark]: color && color === 'dark' }
              )}
            />
            <div
              className={classnames(
                classes['divider-right'],
                { [classes['line-light']]: color && color === 'light' },
                { [classes['line-dark']]: color && color === 'dark' }
              )}
            ></div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withStyles(InlineSubcontentDividerStyles, {
  name: 'InlineSubcontentDivider',
})(InlineSubcontentDivider)
