import { WithStyles, withStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { action, autorun, IReactionDisposer, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { legalStore } from 'stores/legalStore'
import { sliderStore } from 'stores/sliderStore'
import { importFromProject } from 'utils/importHelper'
import { safeHtml } from 'utils/validation'
import { contentStore } from 'stores/contentStore/contentStore'
import { cookiesBannerStore } from 'stores/cookiesBannerStore'
import { StandardButton } from '../elements/buttons'
import { CookiesBannerStyles } from './cookiesBanner.style'

type IEBannerProps = WithStyles<typeof CookiesBannerStyles>

@observer
export class CookiesBannerCls extends React.Component<IEBannerProps> {
  @observable
  shouldRender: boolean = false

  @observable
  banner: JSX.Element | null | undefined = undefined

  disposer: IReactionDisposer

  constructor(a: IEBannerProps) {
    super(a)
    this.disposer = autorun(() => {
      if (sliderStore.showContent) {
        window.setTimeout(() => this.setRender(), 3000)
      }
    })
  }

  async componentDidMount() {
    await this.loadBanner()
  }

  componentWillUnmount() {
    this.disposer()
  }

  async loadBanner() {
    let module: JSX.Element | null = null
    const Component: React.ComponentType | null = await importFromProject(
      'components/Banners/CookiesBanner'
    )
    if (Component) {
      module = <Component />
    }
    this.setBanner(module)
  }

  @bind
  @action
  setBanner(module: JSX.Element | null) {
    this.banner = module
  }

  @bind
  @action
  setRender() {
    this.shouldRender = true
  }

  acceptCookies() {
    cookiesBannerStore.agreeAndHideBanner()
  }

  showMoreInfo() {
    cookiesBannerStore.cookiesInfoCallback()
  }

  render() {
    if (
      this.banner &&
      cookiesBannerStore.bannerIsShown &&
      sliderStore.showContent &&
      this.shouldRender
    ) {
      return this.banner
    }
    const { classes } = this.props
    let message = 'This website uses cookies.'
    let buttonText = 'Accept'
    let moreInfoElement: JSX.Element | string = ''
    if (contentStore.uiElements?.cookiesBanner) {
      moreInfoElement = (
        <span onClick={this.showMoreInfo} className={classes['more-info-text']}>
          {contentStore.uiElements.cookiesBanner.moreInfoText}
        </span>
      )
      message =
        contentStore.uiElements.cookiesBanner.message || 'Cookies usage warning'
      buttonText =
        contentStore.uiElements.cookiesBanner.acceptButtonText || 'Accept'
    }
    return (
      (!!(
        cookiesBannerStore.bannerIsShown &&
        !legalStore.showingLegal &&
        message &&
        buttonText &&
        sliderStore.showContent
      ) && (
        <div
          className={classnames(classes.container, {
            [classes['container-visible']]: this.shouldRender,
          })}
        >
          <div className={classes['inner-container']}>
            <div className={classes['text-content']}>
              <div className={classes['message']} {...safeHtml(message)}></div>
              {moreInfoElement}.
            </div>
            <div className={classes['buttons-container']}>
              <StandardButton
                hoverContainerClassName={classes['button-hover-container']}
                className={classes['accept-button']}
                onClick={this.acceptCookies}
              >
                {buttonText}
              </StandardButton>
            </div>
          </div>
        </div>
      )) ||
      null
    )
  }
}

export const CookiesBanner = withStyles(CookiesBannerStyles, {
  name: 'CookiesBanner',
})(CookiesBannerCls)
