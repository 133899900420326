import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const SliderNavigationButtonStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      display: 'flex',
      height: theme.constants.sizes.desktop.topBarHeight,
      width: theme.constants.sizes.desktop.topBarHeight,
      backgroundColor: 'rgba(219, 219, 219, 0.4)',
      opacity: 1,
      transition: 'opacity 0.3s cubic-bezier(0.5, 1, 0.89, 1)',
    },
    'arrow-icon': {},
    hovered: {
      opacity: 0.8,
      transition: 'opacity 0.3s cubic-bezier(0.5, 1, 0.89, 1)',
    },
  })
