import { bind } from 'bind-decorator'
import { action, observable } from 'mobx'

export class ChaptersBarStore {
  @observable
  onlyShowCurrentChapter: boolean = false

  @bind
  @action
  setOnlyShowCurrentChapter(value: boolean) {
    this.onlyShowCurrentChapter = value
  }
}

export const chaptersBarStore = new ChaptersBarStore()
