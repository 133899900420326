import Prismic from 'prismic-javascript'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { languageStore } from 'stores/languageStore'
import { decodingRouterHelper } from 'stores/routerStore'

const apiEndpoint = `https://${
  process.env.REACT_APP_PRISMIC_REPO_NAME ?? ''
}.cdn.prismic.io/api/v2`

const client = Prismic.client(apiEndpoint)

type Unpacked<T> = T extends Array<infer U>
  ? U
  : T extends (...args: unknown[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

type PrismicApiPromise = ReturnType<typeof Prismic.getApi>
type PrismicApi = Unpacked<PrismicApiPromise>
type PrismicDocument = Unpacked<ReturnType<PrismicApi['getSingle']>>

function linkResolver(doc: PrismicDocument) {
  const lang = doc.lang
  if (lang && languageStore.language !== lang) {
    languageStore.selectLanguage(lang)
  }
  if (doc.uid === undefined) {
    doc.uid = ''
  }
  switch (doc.type) {
    default:
      return `/doc/${doc.type}/${doc.uid}/${doc.id}`
  }
}

interface HistoryProps {
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
}

type IPreview = (props: HistoryProps) => null

const Preview: IPreview = ({ history, location: _location }) => {
  useEffect(() => {
    async function execute() {
      const { token } = decodingRouterHelper.qsParsed
      if (!token) {
        return console.warn('No token available, check your configuration')
      }
      client
        .previewSession(token as string, linkResolver, '/')
        .then((url) => {
          return history.push(url)
        })
        .catch((err) => console.error(err))
    }
    execute().catch((err) => console.error(err))
  })
  return null
}

export default Preview
