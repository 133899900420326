import { AnyObject } from 'utils/types'
import { getErrorMessage } from './errors'

export const projectName = process.env.REACT_APP_PROJECT_NAME ?? 'escher'

type ModuleType<T> = AnyObject & {
  default: T
}

const expectedLoadingModuleErrorRegex = /Cannot\s+find\s+module/
const expectedNullErrorRegex = /Null returned from promise/
const isExpectedError = (error: unknown, regex: RegExp) => {
  const errorMessage = getErrorMessage(error)
  return regex.test(errorMessage)
}
export const isModuleLoadingError = (err: unknown) =>
  isExpectedError(err, expectedLoadingModuleErrorRegex)
export const isNullError = (error: unknown) =>
  isExpectedError(error, expectedNullErrorRegex)

export async function promiseTryCatchHandler<T>(
  promise: Promise<T | null>
): Promise<[T, null] | [null, unknown]> {
  try {
    const promiseResult = await promise
    if (promiseResult === null) {
      throw new Error('Null returned from promise')
    }
    const data: T = promiseResult
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export async function importFromProject<T>(
  path: string,
  shouldThrowModuleLoadingError: boolean = false
): Promise<T | null> {
  const projectComponentPromise = import(`projects/${projectName}/${path}`)
  const [projectData, projectImportError] = await promiseTryCatchHandler<
    ModuleType<T>
  >(projectComponentPromise)
  if (projectData) {
    return projectData.default
  }
  if (!isModuleLoadingError(projectImportError)) {
    throw projectImportError
  }

  return throwErrorsIfNeeded(projectImportError, shouldThrowModuleLoadingError)
}

function throwErrorsIfNeeded(
  mainError: unknown,
  shouldThrowModuleLoadingError?: boolean
) {
  if (mainError && shouldThrowModuleLoadingError) {
    throw mainError
  }
  return null
}

export async function importFromModules<T>(path: string) {
  const modulePromise = import(`modules/${path}`)
  const [module, error] = await promiseTryCatchHandler<ModuleType<T>>(
    modulePromise
  )
  if (module) {
    return module.default
  }
  throw error
}

export async function importFromComponents<T>(path: string) {
  const modulePromise = import(`components/${path}`)
  const [module, error] = await promiseTryCatchHandler<ModuleType<T>>(
    modulePromise
  )
  if (module) {
    return module.default
  }
  throw error
}

export async function loadResource(path: string) {
  const resourcePromise = importFromProject<string>(`components/${path}`, false)

  const [resource] = await promiseTryCatchHandler<string>(resourcePromise)
  if (!resource) {
    const defaultResourcePromise = importFromComponents<string>(`${path}`)
    const [defaultResource] = await promiseTryCatchHandler<string>(
      defaultResourcePromise
    )
    if (!defaultResource) {
      return ''
    }
    return defaultResource
  }
  return resource
}
