/* eslint-disable @typescript-eslint/no-explicit-any */
import { bind } from 'bind-decorator'
import { appConfig } from 'config'
import { DateTime } from 'luxon'
import { runInAction } from 'mobx'
import * as PrismicDOM from 'prismic-dom'
import Prismic from 'prismic-javascript'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi'
import { deserialize } from 'serializr'
import { beautifySubchapterTitle, cutSubcontentType } from 'utils/string'
import {
  Chapter,
  ContentTypes,
  IChapter,
  IHub,
  IHubPrismicData,
  IIntroPage,
  IKnowledgeBase,
  ISubchapter,
  ISubchapterPrismicData,
  IUICategoryPrismicData,
  IUIElements,
  LegalContent,
  PartialChapter,
  Subchapter,
} from './contentStore/contentTypes'
import { IGlossary } from './contentStore/materials'
import { IRawSubcontentData } from './contentStore/subcontent_modules/subcontentInterfaces'
import { languageStore } from './languageStore'
import {
  IPrismicChapter,
  parseBackIssues,
  parseChapter,
  parseCookiesBanner,
  parseGlossary,
  parseIEBanner,
  parseLegal,
  parseMenuItems,
  parseMetaTabs,
  parsePodcastSeries,
  parseSubcontent,
  parseSupportersLogos,
  parseThumbnail,
  parseUnlockContent,
  parseVideoBanner,
} from './prismicHelpers'

type Unpacked<T> = T extends Array<infer U>
  ? U
  : T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

type PrismicApiPromise = ReturnType<typeof Prismic.getApi>
type PrismicApi = Unpacked<PrismicApiPromise>
type PrismicDocument = Unpacked<ReturnType<PrismicApi['getSingle']>>

class PrismicAdapter {
  _api: PrismicApiPromise = {} as any
  _prismicPath: string = ''

  initPrismicPath() {
    this._prismicPath = appConfig.prismicPath
  }

  getApi() {
    this._api = Prismic.getApi(this._prismicPath)
  }

  @bind
  async getAllResults(q: string | string[], maybeOptions?: QueryOptions) {
    let results: PrismicDocument[] = []
    const api = await this._api
    let page = 1
    let more = false

    do {
      const r: ApiSearchResponse = await api.query(q, {
        ...maybeOptions,
        page: page,
      })
      page = r.page + 1
      more = page <= r.total_pages
      results = [...results, ...r.results]
    } while (more)
    return results
  }

  async getLanguageByContentId(id: string) {
    const api = await this._api
    const results = (
      await api.query(Prismic.Predicates.at('document.id', id), {
        lang: '*',
      })
    ).results
    if (results?.[0] && results[0].lang) {
      return results[0].lang
    }
    return null
  }

  async getChapterLanguage(chapterUid: string) {
    const api = await this._api
    const results = (
      await api.query(
        Prismic.Predicates.at(
          `my.${appConfig.prismicModelsNamesMap.page}.uid`,
          chapterUid
        ),
        { lang: '*' }
      )
    ).results
    const languages: string[] = results
      .map((res) => res?.lang)
      .filter((lang) => lang !== undefined) as string[]
    if (languages.length) {
      return languages
    }
    return null
  }

  async fetchChapters(
    options?: QueryOptions,
    fields?: { [key: string]: string },
    ids?: string[]
  ) {
    const api = await this._api
    const fieldQueries = fields
      ? Object.keys(fields).map((f) => {
          return Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.page}.${f}`,
            fields[f]
          )
        })
      : []
    const query =
      ids === undefined
        ? Prismic.Predicates.at(
            'document.type',
            appConfig.prismicModelsNamesMap.page
          )
        : Prismic.Predicates.in('document.id', ids)
    if (options?.fetchAll) {
      delete options.fetchAll
      const results = await this.getAllResults([query, ...fieldQueries], {
        pageSize: 100,
        page: 1,
        lang: languageStore.language,
        ...options,
      })
      return this.saveChapters(results, true)
    }
    const { results, total_pages: nOfPages } = await api.query(
      [query, ...fieldQueries],
      {
        pageSize: 100,
        page: 1,
        lang: languageStore.language,
        ...options,
      }
    )
    return this.saveChapters(results, !!(options?.page === nOfPages))
  }

  async fetchChaptersByIds(ids: string[], options?: QueryOptions) {
    const results = await this.getAllResults(
      [Prismic.Predicates.in('document.id', ids)],
      {
        ...options,
        pageSize: 100,
        lang: languageStore.language,
      }
    )
    return this.saveChapters(results, true)
  }

  saveChapters(data: IPrismicChapter[], isLastPage: boolean) {
    const chapters: IChapter[] = []
    for (const chapter of data) {
      runInAction(() => {
        const newChapter = parseChapter(chapter)
        chapters.push(newChapter)
      })
    }
    return {
      data: chapters.filter((chapter: IChapter) => chapter.idx >= 0),
      isLastPage,
    }
  }

  async fetchSubchapters(
    ids?: string[],
    options?: QueryOptions,
    fields?: { [key: string]: string }
  ) {
    const fieldQueries = fields
      ? Object.keys(fields).map((f) => {
          return Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.section}.${f}`,
            fields[f]
          )
        })
      : []
    const query =
      ids === undefined
        ? Prismic.Predicates.at(
            'document.type',
            appConfig.prismicModelsNamesMap.section
          )
        : Prismic.Predicates.in('document.id', ids)
    const results = await this.getAllResults([query, ...fieldQueries], {
      ...options,
      pageSize: 100,
      lang: languageStore.language,
    })
    const subchapters: ISubchapter[] = []
    for (const subchapter of results) {
      const newSubchapter: ISubchapter = await this._buildSubchapter(subchapter)
      subchapters.push(newSubchapter)
    }
    return subchapters
  }

  private async _buildSubchapter(subchapterData: ISubchapterPrismicData) {
    const data = subchapterData.data
    const subcontent = await parseSubcontent(data.body)
    const newSubchapter: ISubchapter = {
      id: subchapterData.id,
      uid: subchapterData.uid ?? '',
      type: 'subchapter',
      backgroundId: data.bg_main?.id || '',
      fallbackBackgroundId:
        data.link_to_subchapter_fallback_background?.id || '',
      thumbnail: data.thumbnail && parseThumbnail(data.thumbnail),
      title:
        (
          data.subchapter_title &&
          beautifySubchapterTitle(
            PrismicDOM.RichText.asText(data.subchapter_title)
          )
        ).replace('<br/>', '<br/>') || '',
      styledPrefix: data.prefix_vertical_subchapter_title || '',
      sidebarText: PrismicDOM.RichText.asText(data.sidebar_text) || '',
      sectionItems: subcontent,
      textPositionLeft: !!data.text_position_left,
    }
    return newSubchapter
  }

  async fetchFullscreenSubcontent(query?: string[]) {
    let extraQuery: string[] = []
    if (query) {
      extraQuery = query
    }
    const results = await this.getAllResults(
      [
        Prismic.Predicates.any(
          'document.type',
          appConfig.prismicModelsNamesMap.subcontentNamesArray
        ),
        ...extraQuery,
      ],
      { pageSize: 100, lang: languageStore.language }
    )
    const subcontentData: IRawSubcontentData[] = []
    for (const subcontent of results) {
      subcontentData.push({
        id: subcontent.id || '',
        type: subcontent.type && cutSubcontentType(subcontent.type),
        data: subcontent as IRawSubcontentData['data'],
      })
    }
    return subcontentData
  }

  async fetchVideoSubcontents() {
    const videoQuery = Prismic.Predicates.at(
      'document.type',
      appConfig.prismicModelsNamesMap.subcontentNamesArray[0]
    )
    return this.fetchFullscreenSubcontent([videoQuery])
  }

  async fetchImpressum() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      { pageSize: 100, lang: languageStore.language }
    )
    const impressum: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.impressumSlug) >=
        0
      ) {
        const data = document.data
        impressum.push({
          id: document.id || '',
          title: PrismicDOM.RichText.asText(data.title) || '',
          sections: parseLegal(data.body),
          supportersLogos: parseSupportersLogos(data.logos_supporters) ?? [],
        })
      }
    }
    return impressum[0]
  }

  async fetchQuellen() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      { pageSize: 100, lang: languageStore.language }
    )
    const quellen: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.quellenSlug) >= 0
      ) {
        const data = document.data
        quellen.push({
          id: document.id || '',
          title: PrismicDOM.RichText.asText(data.title) || '',
          sections: parseLegal(data.body),
        })
      }
    }
    return quellen[0]
  }

  async fetchDataPrivacy() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      { pageSize: 100, lang: languageStore.language }
    )
    const dataPrivacy: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(
          appConfig.prismicModelsNamesMap.dataPrivacySlug
        ) >= 0
      ) {
        const data = document.data
        dataPrivacy.push({
          id: document.id || '',
          title: PrismicDOM.RichText.asText(data.title) || '',
          sections: parseLegal(data.body),
        })
      }
    }
    return dataPrivacy[0]
  }

  async fetchGlossary() {
    const results = await this.getAllResults(
      Prismic.Predicates.at('document.type', 'glossar'),
      {
        pageSize: 100,
        lang: languageStore.language,
      }
    )
    let glossary: IGlossary | null = null
    if (!results[0]) {
      return
    }
    glossary = {
      id: results[0].id || '',
      uid: results[0].uid ?? '',
      letters: parseGlossary(results[0].data.body) || [],
    }

    return glossary
  }

  async fetchIntroPage() {
    const api = await this._api
    const introPage: IIntroPage[] = []
    for (const language of languageStore.languages) {
      const lang = language.code
      const results = (
        await api.query(Prismic.Predicates.at('document.type', 'intro_page'), {
          lang,
        })
      ).results
      if (!results[0]) {
        introPage.push({
          lang,
          title: '',
          secondaryTitle: '',
          richTitle: '',
          backgroundImage: '',
          startButtonText: '',
          languageSelectionPhrase: '',
          soundSwitchOn: '',
          soundSwitchOff: '',
          titlePoster: '',
          introVideo: '',
          introAnimation: {
            desktop: '',
            tablet: '',
            mobile: '',
          },
          introductionMovieTitle: '',
          skipVideoButtonText: '',
          backgroundVideoLoopDesktop: '',
          backgroundVideoLoopMobile: '',
          promotionBar: {
            promoBarText: '',
            promoButtonText: '',
            promoButtonLink: '',
            promoBarBackgroundColor: '',
          },
          awards: [
            {
              logo: '',
              logoLink: '',
            },
          ],
        })
        continue
      }
      const data = results[0].data
      introPage.push({
        lang,
        title: (data.title && PrismicDOM.RichText.asText(data.title)) || '',
        richTitle:
          (data.rich_title && PrismicDOM.RichText.asHtml(data.rich_title)) ||
          '',
        secondaryTitle:
          (data.secondary_title &&
            PrismicDOM.RichText.asText(data.secondary_title)) ||
          '',
        backgroundImage: data.background_image?.url || '',
        startButtonText:
          (data.start_button_text &&
            PrismicDOM.RichText.asText(data.start_button_text)) ||
          '',
        introductionMovieTitle: data.introduction_movie_title || '',
        skipVideoButtonText: data.skip_intro_movie_button_text || '',
        languageSelectionPhrase:
          (data.language_selection &&
            PrismicDOM.RichText.asText(data.language_selection)) ||
          '',
        soundSwitchOn:
          (data.sound_switch_on &&
            PrismicDOM.RichText.asHtml(data.sound_switch_on)) ||
          '',
        soundSwitchOff:
          (data.sound_switch_off &&
            PrismicDOM.RichText.asHtml(data.sound_switch_off)) ||
          '',
        titlePoster: data.title_poster?.url || '',
        introVideo: data['intro-video']?.url || '',
        introAnimation:
          {
            desktop: data.intro_animation_desktop?.url,
            tablet: data.intro_animation_tablet?.url,
            mobile: data.intro_animation_mobile?.url,
          } || {},
        backgroundVideoLoopDesktop:
          data.background_video_loop_desktop?.url || '',
        backgroundVideoLoopMobile: data.background_video_loop_mobile?.url || '',
        promotionBar: {
          promoBarText:
            (data.promo_bar_text &&
              PrismicDOM.RichText.asHtml(data.promo_bar_text)) ||
            '',
          promoButtonText: data.promo_button_text || '',
          promoButtonLink: data.promo_button_link?.url || '',
          promoBarBackgroundColor: data.promo_bar_background_color,
        },
        awards: data.awards
          ? data.awards.map((awardData: any) => {
              return {
                logo: awardData?.logo?.url,
                logoLink: awardData?.logo_link?.url,
              }
            })
          : [],
      })
    }
    return introPage
  }

  async fetchKnowledgeBase() {
    const api = await this._api
    const knowledgeBase: IKnowledgeBase[] = []
    for (const language of languageStore.languages) {
      const lang = language.code
      const results = (
        await api.query(
          Prismic.Predicates.at('document.type', 'knowledge_base'),
          {
            lang,
          }
        )
      ).results
      if (!results[0]) {
        knowledgeBase.push({
          lang,
          buttonTextToMain: '',
          title: '',
          subtitle: '',
          description: '',
          logoTopLeft: '',
          logoDescriptionTopLeft: '',
          backgroundImage: '',
        })
        continue
      }
      const data = results[0].data
      knowledgeBase.push({
        lang,
        buttonTextToMain: data.button_text_to_main ?? '',
        title: data.title ?? '',
        subtitle: data.subtitle ?? '',
        description:
          (data.description && PrismicDOM.RichText.asHtml(data.description)) ??
          '',
        logoTopLeft: data.logo_top_left.url ?? '',
        logoDescriptionTopLeft: data.logo_description_top_left ?? '',
        backgroundImage: data.background_image.url ?? '',
      })
    }

    return knowledgeBase
  }

  async fetchHub() {
    const api = await this._api
    let Hub: IHub | null = null
    let results: IHubPrismicData[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.type', 'hub'), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      Hub = null
    } else {
      const data = results[0].data
      const createPartialChapter = (
        chapter: IPrismicChapter
      ): PartialChapter => {
        const data = chapter.data
        return {
          id: chapter?.id || '',
          uid: chapter?.uid ?? '',
          type: 'chapter' as ContentTypes,
          idx: !(data.chapter_number ?? data.chapter_number === 0)
            ? 0
            : data.chapter_number > 0
            ? data.chapter_number
            : -1,
          title:
            (data.chapter_title &&
              PrismicDOM.RichText.asText(data.chapter_title)) ??
            '',
          category: data.category ?? '',
          author:
            (data.author && PrismicDOM.RichText.asHtml(data.author)) ?? '',
          thumbnail: (data.thumbnail && parseThumbnail(data.thumbnail)) ?? null,
          lottieChapterTitleAnimation:
            data.lottie_chapter_title_animation?.url ?? '',
          // Ranking is 1-3, so to show missing ranking use -1
          ranking: parseInt(data.ranking) || -1,
          publicationDate: data.date_of_publication ?? DateTime.local(),
          hubTeaserText:
            (data.hub_teaser_text &&
              PrismicDOM.RichText.asHtml(data.hub_teaser_text)) ??
            '',
          tags: data.tags
            ? data.tags.map((tagData: { tag: string }) => tagData.tag)
            : [],
          subchaptersIds: data.subchapters
            ? data.subchapters.map(
                (subchapterData: { [id: string]: ISubchapter }) =>
                  subchapterData.subchapter.id
              )
            : [],
        }
      }
      const featuredData1 = await this.fetchById(data.featured_article_1.id)
      const featuredData2 = await this.fetchById(data.featured_article_2.id)
      const featuredArticlesTopBannerIds = data.featured_top_banner.map(
        (val) => val.featured_article_top_banner.id
      )
      const featured1: PartialChapter | null = featuredData1
        ? createPartialChapter(featuredData1.data as IPrismicChapter)
        : null
      const featured2: PartialChapter | null = featuredData2
        ? createPartialChapter(featuredData2.data as IPrismicChapter)
        : null

      const hub = {
        dummyThumbnail: data.dummy_image.url,
        articlesLoaded: data.default_amount_of_articles_shown,
        featuredArticlesTopBannerIds,
        featuredArticle1: featured1,
        featuredArticle2: featured2,
        subscribeData: {
          type: 'subscribe' as const,
          text:
            data.subscribe_description.length > 0
              ? PrismicDOM.RichText.asHtml(data.subscribe_description)
              : '',
          buttonText: data.button_text_subscribe,
          placeholderText: data.subscribe_input_placeholder_text,
          mailTo: data.mailto_address_button_subscribe,
          mailToSubject:
            (data.mailto_subject_subscribe &&
              PrismicDOM.RichText.asText(data.mailto_subject_subscribe)) ||
            '',
          mailToBody:
            (data.mailto_body_subscribe &&
              PrismicDOM.RichText.asText(data.mailto_body_subscribe)) ||
            '',
        },
        pensionersData: {
          type: 'pensioners' as const,
          text:
            data.subscribe_description.length > 0
              ? PrismicDOM.RichText.asHtml(data.pensioners_description)
              : '',
          buttonText: data.button_text_pensioners,
          mailTo: data.mailto_address_button_pensioners,
          mailToSubject:
            (data.mailto_subject_pensioners &&
              PrismicDOM.RichText.asText(data.mailto_subject_pensioners)) ||
            '',
          mailToBody:
            (data.mailto_body_pensioners &&
              PrismicDOM.RichText.asHtml(data.mailto_body_pensioners)) ||
            '',
        },
        aboutLiveData: {
          type: 'about' as const,
          text:
            data.subscribe_description.length > 0
              ? PrismicDOM.RichText.asHtml(data.about_live)
              : '',
          buttonText: data.button_text_about_live,
          mailTo: data.mailto_address_button_about_live,
          mailToBody:
            (data.mailto_subject_about_live &&
              PrismicDOM.RichText.asText(data.mailto_subject_about_live)) ||
            '',
        },

        backIssues: parseBackIssues(data.body) ?? [],
      }
      Hub = hub
    }
    return Hub
  }

  async fetchUIElements() {
    const results = await this.getAllResults(
      Prismic.Predicates.at('document.type', 'ui_elements'),
      {
        pageSize: 100,
        lang: languageStore.language,
      }
    )
    if (!results || !results[0]) {
      return null
    }
    const UIElements: IUIElements = {} as any

    // Carl Lutz, Nuclear Games and SNB use a prismic field "scroll_indicator_text" in ui_elements but for nuclear games we need it as rich text. This is to correctly build the variable.
    const scrollIndicatorText = results[0].data.scroll_indicator_text ?? ''
    UIElements.idleTimePopupText = results[0].data.idle_time_popup_text ?? ''
    UIElements.blockedAudioAutoplayTextInfo =
      (results[0].data.blocked_audio_autoplay_text_info &&
        PrismicDOM.RichText.asHtml(
          results[0].data.blocked_audio_autoplay_text_info
        )) ||
      ''
    UIElements.scrollToIntroText =
      typeof scrollIndicatorText === 'object'
        ? PrismicDOM.RichText.asHtml(scrollIndicatorText)
        : scrollIndicatorText
    UIElements.soundIconOn = results[0].data.sound_icon_on?.url || ''
    UIElements.soundIconOff = results[0].data.sound_icon_off?.url || ''
    UIElements.soundOnPhrase = results[0].data.sound_on_phrase || ''
    UIElements.soundOffPhrase = results[0].data.sound_off_phrase || ''
    UIElements.SEODescription = results[0].data.seo_app_description || ''
    UIElements.SEOImage = results[0].data.seo_app_image?.url || ''
    UIElements.documentaryURL = results[0].data.documentary_url?.url || ''
    UIElements.SEOTitle =
      (results[0].data.seo_app_title &&
        PrismicDOM.RichText.asText(results[0].data.seo_app_title)) ||
      ''
    UIElements.SEOSubtitle =
      (results[0].data.seo_app_subtitle &&
        PrismicDOM.RichText.asHtml(results[0].data.seo_app_subtitle)) ||
      ''
    UIElements.SEOKeywords =
      results[0].data.seo_app_keywords?.map(
        (item: { keyword: string }) => item.keyword
      ) || []
    UIElements.chapterDateFormat =
      (results[0].data.chapter_date_format &&
        PrismicDOM.RichText.asText(results[0].data.chapter_date_format)) ||
      ''
    UIElements.loadingSpinner = results[0].data.loading_spinner?.url || ''
    UIElements.podcastSeries = []
    for (const item of results[0].data.body) {
      if (item.slice_type === 'podcast_series') {
        const newSeries = parsePodcastSeries(item)
        if (newSeries) {
          UIElements.podcastSeries.push(newSeries)
        }
      }
      if (item.slice_type === 'unlock_content') {
        UIElements.unlockContent = parseUnlockContent(item) ?? ({} as any)
      }
      if (item.slice_type === 'ie_banner') {
        UIElements.ieBanner = parseIEBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'cookies_banner') {
        UIElements.cookiesBanner = parseCookiesBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'meta_tabs') {
        UIElements.metaTabs = parseMetaTabs(item) ?? []
      }
      if (item.slice_type === 'video_documentary_banner') {
        UIElements.videoBanner = parseVideoBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'menu_items') {
        UIElements.menuItems = parseMenuItems(item) ?? ({} as any)
      }
      if (item.slice_type === 'static_ui_texts') {
        const {
          all_stories: allStories,
          recent,
          tagged_by: taggedBy,
          read_now: readNow,
          more_articles: moreArticles,
          discover_our_categories: discoverOurCategories,
          read_abstract: readAbstract,
          contents,
          chapter: chapterText,
          related_articles_info: relatedArticlesInfo,
          rotate_device: rotateDeviceText,
          ie_previous_chapter_info_text: IEPreviousChapterButtonText,
          ie_next_chapter_info_text: IENextChapterButtonText,
        } = item.primary
        UIElements.staticUITexts =
          {
            allStories,
            recent,
            taggedBy,
            readNow,
            moreArticles,
            discoverOurCategories,
            readAbstract,
            contents,
            chapterText,
            relatedArticlesInfo,
            rotateDeviceText,
            IEPreviousChapterButtonText,
            IENextChapterButtonText,
            categories: item.items.map((c: IUICategoryPrismicData) => {
              return {
                name: c?.category_name[0]?.text,
                filter: c?.filter_name,
                longName: c?.category_long_name[0]?.text,
                description: c?.category_description[0]?.text,
                img: parseThumbnail(c?.image_category_thumbnail),
                goToText: c?.category_go_to_text,
              }
            }),
          } || {}
      }
      if (item.slice_type === 'loading_screen') {
        const {
          loading_spinner: loadingSpinner,
          background_image: backgroundImage,
          infotext_fallback_version: infotextFallbackVersion,
          fallback_no_button_text: fallbackNoButtonText,
          fallback_yes_button_text: fallbackYesButtonText,
          info_text_after_rotating_device: infoTextAfterRotatingDevice,
        } = item.primary
        UIElements.loadingScreen =
          {
            loadingSpinner: loadingSpinner.url,
            backgroundImage: backgroundImage.url,
            infotextFallbackVersion: PrismicDOM.RichText.asHtml(
              infotextFallbackVersion
            ),
            fallbackNoButtonText: fallbackNoButtonText ?? '',
            fallbackYesButtonText: fallbackYesButtonText ?? '',
            infoTextAfterRotatingDevice: infoTextAfterRotatingDevice ?? '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            waitingTexts: item.items.map((w: { waiting_text: string }) => ({
              text: PrismicDOM.RichText.asHtml(w?.waiting_text),
            })),
          } || {}
      }
      if (item.slice_type === 'podcast') {
        const {
          button_text_show_more: buttonTextShowMore,
          button_text_show_less: buttonTextShowLess,
          related_podcasts_title: relatedPodcastsTitle,
          related_podcasts_button_text_show_more: relatedPodcastsButtonTextShowMore,
          mobile_description_title: mobileDescriptionTitle,
          initial_number_of_related_episodes: initialNumberOfRelatedEpisodes,
        } = item.primary
        UIElements.podcast =
          {
            buttonTextShowMore,
            buttonTextShowLess,
            relatedPodcastsTitle,
            relatedPodcastsButtonTextShowMore,
            mobileDescriptionTitle,
            initialNumberOfRelatedEpisodes,
          } || {}
      }
      if (item.slice_type === 'marketing_popup') {
        const data = item.primary
        UIElements.signupBanner =
          {
            title:
              (data.newsletter_sign_up_title &&
                PrismicDOM.RichText.asText(data.newsletter_sign_up_title)) ||
              '',
            description:
              (data.newsletter_sign_up_description &&
                PrismicDOM.RichText.asHtml(
                  data.newsletter_sign_up_description
                )) ||
              '',
            inputPlaceholder: data.newsletter_sign_up_input_placeholder || '',
            buttonText: data.newsletter_sign_up_button || '',
            checkboxLabel:
              (data.newsletter_sign_up_checkbox_label &&
                PrismicDOM.RichText.asHtml(
                  data.newsletter_sign_up_checkbox_label
                )) ||
              '',
            emailActionUrl: data.action_url_to_mailchimp?.url || '',
            idMailchimp: data.id_mailchimp || '',
            userMailchimp: data.user_mailchimp_no || '',
            emailMailchimp: data.name_attribute_mailchimp_for_email_field || '',
            background: data.background?.url || '',
          } || {}
      }
    }
    return UIElements
  }

  async fetchChapterById(id?: string) {
    if (!id) {
      return
    }
    const api = await this._api
    let results: IPrismicChapter[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newChapter = deserialize(Chapter, parseChapter(results[0]))
    return newChapter
  }

  async fetchChapterByUid(uid: string) {
    const api = await this._api
    let results: IPrismicChapter[] = []
    try {
      results = (
        await api.query(
          Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.page}.uid`,
            uid
          ),
          {
            lang: languageStore.language,
          }
        )
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newChapter = parseChapter(results[0])
    return newChapter
  }

  async fetchSubchapterById(id: string) {
    const api = await this._api
    let results: ISubchapterPrismicData[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newSubchapter = await this._buildSubchapter(results[0])
    return deserialize(Subchapter, newSubchapter)
  }

  async fetchById(id: string) {
    if (!id) {
      return null
    }
    const api = await this._api
    let results: Array<IPrismicChapter | IRawSubcontentData> = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const result = {
      id: results[0].id || '',
      type:
        (results[0] as IRawSubcontentData).type &&
        cutSubcontentType((results[0] as IRawSubcontentData).type),
      data: results[0],
    }
    return result
  }

  async fetchByIds(ids: string[]) {
    const api = await this._api
    let results: Array<IPrismicChapter | IRawSubcontentData> = []
    try {
      results = (
        await api.getByIDs(ids, {
          lang: languageStore.language,
        })
      ).results
      if (!results || !results[0]) {
        return null
      }
      const result = results.map((r, idx) => {
        return {
          id: results[idx].id || '',
          type:
            (results[idx] as IRawSubcontentData).type &&
            cutSubcontentType((results[idx] as IRawSubcontentData).type),
          data: results[idx],
        }
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

export const prismicAdapter = new PrismicAdapter()
