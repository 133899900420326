import { runInAction } from 'mobx'
import { deserialize } from 'serializr'
import { importFromProject } from 'utils/importHelper'
import { subcontentDataAdapter } from '../cmsAdapters/subcontentDataAdapter'
import { FullscreenSubcontent } from './fullscreenSubcontent'
import { IRawSubcontentData } from './subcontentInterfaces'

export { FullscreenSubcontent } from './fullscreenSubcontent'

export async function createSubcontent(
  subcontentDataItem: IRawSubcontentData,
  parms = {}
): Promise<FullscreenSubcontent | null> {
  let clazz: (new () => FullscreenSubcontent) | null = null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let subcontentType: any
  let res: FullscreenSubcontent | null = null
  const shouldThrowModuleLoadingError = true
  try {
    const resource = await importFromProject(
      `subcontent_modules/${subcontentDataItem.type}/subcontent.tsx`,
      shouldThrowModuleLoadingError
    )
    subcontentType = resource
  } catch (err) {
    try {
      const resource = (await import(`./${subcontentDataItem.type}`)).default
      subcontentType = resource
    } catch (err) {
      subcontentType = null
    }
  }
  const parseData = (await subcontentDataAdapter())[subcontentDataItem.type]
  if (subcontentType) {
    runInAction(() => {
      clazz = subcontentType
      res = deserialize(
        clazz!, // eslint-disable-line
        parseData(subcontentDataItem.data),
        undefined,
        parms
      )
    })
  }
  return res
}
