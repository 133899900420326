import { DateTime } from 'luxon'
import * as PrismicDOM from 'prismic-dom'
import { camelify } from 'utils/string'
import { IWithUrl } from './contentStore/cmsAdapters/prismic/prismicSubcontentDataProcessor'
import {
  BackIssue,
  BackIssuesSection,
  CookiesBanner,
  IChapter,
  IEBanner,
  ISubchapter,
  LegalSection,
  LegalSectionEntry,
  MetaTab,
  SupporterLogo,
  Thumbnail,
  VideoBanner,
  MenuItems,
  LegalItemsInMenu,
  UnlockContent,
  PodcastSerie,
  SupportersLogos,
  IPodcastItemPrismicData,
} from './contentStore/contentTypes'
import { ILetter } from './contentStore/materials'
import { createSectionItem } from './contentStore/mobxHelpers'
import { SectionItem } from './contentStore/sectionItems/SectionItem'
import { IImageData } from './contentStore/subcontent_modules/subcontentInterfaces'

export interface IPrismicParagraph {
  paragraph: unknown
}

export interface ISubcontentPrismicData {
  /* eslint-disable */
  slice_type: string
  /* eslint-enable */
}

export interface IPrismicImageSlice {
  items: Array<{
    /* eslint-disable */
    image: IWithUrl
    image_description: unknown
    image_title: unknown
    /* eslint-enable */
  }>
}

export interface IPrismicLegal {
  /* eslint-disable */
  primary: {
    section_title: unknown
  }
  items: Array<{
    section_title?: unknown
    section_running_text?: unknown
    year_for_logos?: number
  }>
  /* eslint-enable */
}

export interface IPrismicGlossary {
  /* eslint-disable */
  primary: {
    letter_of_alphabet: string
  }
  items: Array<{
    glossar_item?: unknown
    glossar_item_multiline?: unknown
    glossar_item_text?: unknown
  }>
  /* eslint-enable */
}

export interface IPrismicBackIssues {
  /* eslint-disable */
  primary: {
    teaser_title_section: unknown
    initial_display_teaser: number
    teaser_text_title: unknown
    teaser_text_description: unknown
    back_issues_title: unknown
    back_issues_subtitle: unknown
    initial_display_all: number
    back_to_hub_text: string
    download_button_text: string
    alternative_download_button_text: string
    order_on_external_button_text: string
    load_more_button_text: string
    teaser_more_button_text: string
  }
  items: Array<{
    cover: IWithUrl
    title: unknown
    subtitle: unknown
    description: unknown
    order_per_email: boolean
    order_on_external: boolean
    order_on_external_link: IWithUrl
    pdf: IWithUrl
    mailto_address: string
    mailto_subject: string
    mailto_body: string
  }>
  /* eslint-enable */
}

export interface IPrismicSupportersLogos {
  /* eslint-disable */
  year_for_logos?: string
  logo?: IWithUrl
  link?: {
    id: string
  }
  /* eslint-enable */
}

export interface IPrismicIEBannerData {
  /* eslint-disable */
  primary: {
    header?: unknown
    message_english?: unknown
    message_german?: unknown
    ie_logo?: IWithUrl
  }
  /* eslint-enable */
}

export interface IPrismicUnlockContentData {
  /* eslint-disable */
  primary: {
    header?: unknown
    title?: unknown
    warning_text?: unknown
    back_button_text?: string
    unlock_button_text?: string
    thumbnail?: IWithUrl
  }
  items: Array<{ forbidden_url: string }>
  /* eslint-enable */
}

export interface IPrismicCookiesBannerData {
  /* eslint-disable */
  primary: {
    message?: unknown
    accept_button_text?: unknown
    more_information_text?: unknown
  }
  /* eslint-enable */
}
export interface IPrismicMetaTabs {
  /* eslint-disable */
  items: Array<{
    tab_name?: unknown
    displayed_title?: unknown
  }>
  /* eslint-enable */
}

export interface IPrismicMenuItems {
  /* eslint-disable */
  primary: {
    button_text_chapter_overview?: string
    button_text_link_to_sequence?: string
    button_text_start_chapter?: string
    placeholder_image?: IWithUrl
    close_menu_text?: string
    back_to_landing_text?: string
    knowledge_base_link_title?: string
    knowledge_base_link_desc?: unknown
    documentary_link_title?: string
    documentary_link_description?: unknown
  }
  items: Array<{
    legal_display?: string
    legal_link?: IWithUrl
  }>
  /* eslint-enable */
}
export interface IPrismicVideoBanner {
  /* eslint-disable */
  primary: {
    text?: unknown
    check_out_button_text?: unknown
    thumbnail?: IWithUrl
    background?: IWithUrl
  }
  /* eslint-enable */
}

export interface IPrismicChapter {
  /* eslint-disable */
  id: string
  uid?: string
  alternate_languages: Array<{
    lang: string
    uid?: string
  }>
  data: {
    chapter_number?: number
    chapter_title?: string
    chapter_color?: string
    category?: string
    author?: string
    lead?: string
    next_chapter_preview_text?: string
    abstract?: string
    seo_or_social_media_description_of_the_chapter?: string
    subtitle?: string
    thumbnail?: IWithUrl
    background_image?: IWithUrl
    world_image?: IWithUrl
    world_marker?: IWithUrl
    lottie_chapter_title_animation?: IWithUrl
    intro_text?: string
    next_chapter_button_text?: string
    background?: { id: string }
    intro_video_mobile?: IWithUrl
    intro_video_desktop?: IWithUrl
    subchapters?: Array<{ [id: string]: ISubchapter }>
    next_chapter_background?: IWithUrl
    is_featured_with_background_image?: boolean
    is_featured_with_gray_background?: boolean
    ranking: string
    is_featured_on_top_banner?: boolean
    date_of_publication?: DateTime
    hub_teaser_text?: string
    related_articles?: Array<{
      related_article: {
        id: string
      }
    }>
    tags?: Array<{ tag: string }>
    no_cover?: boolean
    is_scrollytelling_chapter?: boolean
    icon_before_headline?: IWithUrl
    duration_of_audio?: number
  }

  /* eslint-enable */
}

export const parseSubcontent = async (
  subcontentData: ISubcontentPrismicData[]
) => {
  const subcontentArray: SectionItem[] = []
  for (const subcontentItem of subcontentData) {
    const data = {
      type: camelify(subcontentItem.slice_type),
      data: subcontentItem,
    }
    const subcontent: SectionItem | null = await createSectionItem(data)
    if (subcontent) {
      subcontentArray.push(subcontent)
    }
  }
  return subcontentArray
}

export const parseImages = (imageSlices: IPrismicImageSlice[] = []) => {
  const images: IImageData[] = []
  let count = 0
  for (const imageSlice of imageSlices) {
    for (const imageData of imageSlice.items) {
      images.push({
        url: imageData.image?.url ?? '',
        description:
          PrismicDOM.RichText.asText(imageData.image_description) ?? '',
        title: PrismicDOM.RichText.asText(imageData.image_title) ?? '',
      })
      count++
    }
  }
  return {
    images,
    count,
  }
}

export const parseTextParagraphs = (
  paragraphsData: IPrismicParagraph[] = []
) => {
  const paragraphs: string[] = []
  const regex = /(<a\s+href="[^"]*")(>)/gim
  for (const paragraphData of paragraphsData) {
    const str = PrismicDOM.RichText.asHtml(paragraphData.paragraph)
    const blankAnchors = str.replace(
      regex,
      (match, start: string, end: string) => start + ' target="_blank"' + end
    )
    paragraphs.push(blankAnchors)
  }
  return paragraphs
}

export const parseLegal = (inputData: IPrismicLegal[]) => {
  const result: LegalSection[] = []
  for (const item of inputData) {
    const title = PrismicDOM.RichText.asText(item.primary.section_title) ?? ''
    const entries: LegalSectionEntry[] = []
    for (const kapitel of item.items) {
      entries.push({
        title: PrismicDOM.RichText.asText(kapitel.section_title) || '',
        entry: PrismicDOM.RichText.asHtml(kapitel.section_running_text).replace(
          '&lt;br/&gt;',
          '<br/>'
        ),
        year: kapitel.year_for_logos?.toString() ?? '',
      })
    }
    result.push({
      title,
      entries,
    })
  }
  return result
}

export const parseBackIssues = (inputData: IPrismicBackIssues[]) => {
  if (!inputData || !inputData.length) return []
  const result: BackIssuesSection[] = []
  for (const item of inputData) {
    if (!item.primary) return null
    const teaserTitleSection =
      PrismicDOM.RichText.asText(item.primary.teaser_title_section) ?? ''
    const initialDisplayTeaser = item.primary.initial_display_teaser ?? 3
    const teaserTextTitle =
      PrismicDOM.RichText.asText(item.primary.teaser_text_title) ?? ''
    const teaserTextDescription =
      PrismicDOM.RichText.asHtml(item.primary.teaser_text_description) ?? ''
    const backIssuesTitle =
      PrismicDOM.RichText.asText(item.primary.back_issues_title) ?? ''
    const backIssuesSubtitle =
      PrismicDOM.RichText.asText(item.primary.back_issues_subtitle) ?? ''
    const initialDisplayAll = item.primary.initial_display_all ?? 6
    const issuesLoaded = initialDisplayAll
    const backToHubText = item.primary.back_to_hub_text ?? ''
    const downloadButtonText = item.primary.download_button_text ?? ''
    const alternativeDownloadButtonText =
      item.primary.alternative_download_button_text ?? ''
    const orderOnExternalButtonText =
      item.primary.order_on_external_button_text ?? ''
    const loadMoreButtonText = item.primary.load_more_button_text ?? ''
    const teaserMoreButtonText = item.primary.teaser_more_button_text ?? ''
    const backIssueItems: BackIssue[] = []
    for (const backIssue of item.items) {
      backIssueItems.push({
        title: PrismicDOM.RichText.asText(backIssue.title) ?? '',
        subtitle: PrismicDOM.RichText.asText(backIssue.subtitle) ?? '',
        description: PrismicDOM.RichText.asHtml(backIssue.description).replace(
          '&lt;br/&gt;',
          '<br/>'
        ),
        orderPerEmail: backIssue.order_per_email,
        orderOnExternal: backIssue.order_on_external,
        orderOnExternalLink: backIssue.order_on_external_link?.url ?? '',
        cover: backIssue.cover.url ?? '',
        pdf: backIssue.pdf.url ?? '',
        mailToAddress: backIssue.mailto_address ?? '',
        mailToSubject: backIssue.mailto_subject ?? '',
        mailToBody:
          PrismicDOM.RichText.asHtml(backIssue.mailto_body)
            .replace(/<\/p>/gm, '\n')
            .replace(/(<([^>]+)>)/gim, '') ?? '',
      })
    }
    result.push({
      teaserTitleSection,
      initialDisplayTeaser,
      teaserTextTitle,
      teaserTextDescription,
      backIssuesTitle,
      backIssuesSubtitle,
      initialDisplayAll,
      issuesLoaded,
      backToHubText,
      downloadButtonText,
      alternativeDownloadButtonText,
      orderOnExternalButtonText,
      loadMoreButtonText,
      teaserMoreButtonText,
      backIssueItems,
    })
  }
  return result
}

export const parseGlossary = (data: IPrismicGlossary[]) => {
  const result: ILetter[] = []
  for (const item of data) {
    const letter = item.primary.letter_of_alphabet?.toLowerCase() ?? ''
    const items = []
    for (const word of item.items) {
      items.push({
        title: PrismicDOM.RichText.asText(word.glossar_item) ?? '',
        titleMultiline:
          PrismicDOM.RichText.asHtml(word.glossar_item_multiline) ?? '',
        text: PrismicDOM.RichText.asText(word.glossar_item_text) ?? '',
      })
    }
    result.push({
      letter,
      items,
    })
  }
  return result
}

export const parseSupportersLogos = (inputData: IPrismicSupportersLogos[]) => {
  if (!inputData) {
    return
  }

  const result: SupportersLogos[] = []
  const resultsMap: { [year: string]: SupporterLogo[] } = {}
  for (const logoData of inputData) {
    const year = logoData.year_for_logos ?? ''
    if (!resultsMap[year]) {
      resultsMap[year] = []
    }
    resultsMap[year].push({
      image: logoData.logo?.url ?? '',
      link: logoData.link?.id ?? '',
    })
  }
  for (const year in resultsMap) {
    result.push({
      year,
      logos: resultsMap[year],
    })
  }
  return result
}

export const parseIEBanner = (inputData: IPrismicIEBannerData) => {
  if (!inputData) return

  const result: IEBanner = {
    header:
      ((inputData.primary.header &&
        PrismicDOM.RichText.asText(inputData.primary.header)) as string) ?? '',
    logo: inputData.primary.ie_logo?.url ?? '',
    textEnglish:
      ((inputData.primary.message_english &&
        PrismicDOM.RichText.asHtml(
          inputData.primary.message_english
        )) as string) ?? '',
    textGerman:
      ((inputData.primary.message_german &&
        PrismicDOM.RichText.asHtml(
          inputData.primary.message_german
        )) as string) ?? '',
  }
  return result
}

export const parseUnlockContent = (inputData: IPrismicUnlockContentData) => {
  if (!inputData) return

  const result: UnlockContent = {
    header:
      ((inputData.primary.header &&
        PrismicDOM.RichText.asText(inputData.primary.header)) as string) ?? '',
    title:
      ((inputData.primary.title &&
        PrismicDOM.RichText.asText(inputData.primary.title)) as string) ?? '',
    warningText:
      ((inputData.primary.warning_text &&
        PrismicDOM.RichText.asHtml(
          inputData.primary.warning_text
        )) as string) ?? '',
    backButtonText: inputData.primary.back_button_text ?? '',
    unlockButtonText: inputData.primary.unlock_button_text ?? '',
    thumbnail: inputData.primary.thumbnail?.url ?? '',
    forbiddenURLs: inputData.items?.map((item) => item.forbidden_url) ?? [],
  }
  return result
}

export const parseCookiesBanner = (inputData: IPrismicCookiesBannerData) => {
  if (!inputData) return

  const result: CookiesBanner = {
    message:
      ((inputData.primary.message &&
        PrismicDOM.RichText.asHtml(inputData.primary.message)) as string) ?? '',
    acceptButtonText:
      ((inputData.primary.accept_button_text &&
        PrismicDOM.RichText.asText(
          inputData.primary.accept_button_text
        )) as string) ?? '',
    moreInfoText:
      ((inputData.primary.more_information_text &&
        PrismicDOM.RichText.asText(
          inputData.primary.more_information_text
        )) as string) ?? '',
  }
  return result
}

export const parseMetaTabs = (inputData: IPrismicMetaTabs) => {
  if (!inputData ?? !inputData.items) return

  const result: MetaTab[] = []
  for (const item of inputData.items) {
    result.push({
      name:
        ((item.tab_name &&
          PrismicDOM.RichText.asText(item.tab_name)) as string) ?? '',
      displayedTitle:
        ((item.displayed_title &&
          PrismicDOM.RichText.asText(item.displayed_title)) as string) ?? '',
    })
  }
  return result
}

export const parseVideoBanner = (inputData: IPrismicVideoBanner) => {
  if (!inputData) return

  const result: VideoBanner = {
    text:
      ((inputData.primary.text &&
        PrismicDOM.RichText.asHtml(inputData.primary.text)) as string) ?? '',
    buttonText:
      ((inputData.primary.check_out_button_text &&
        PrismicDOM.RichText.asText(
          inputData.primary.check_out_button_text
        )) as string) ?? '',
    banner: inputData.primary.thumbnail?.url ?? '',
    background: inputData.primary.background?.url ?? '',
  }
  return result
}

export const parseMenuItems = (inputData: IPrismicMenuItems) => {
  if (!inputData ?? !inputData.items) return
  const resultLegalEntries: LegalItemsInMenu[] = []
  for (const item of inputData.items) {
    resultLegalEntries.push({
      legalDisplay: item.legal_display ?? '',
      legalLink: item.legal_link?.url ?? '',
    })
  }
  const result: MenuItems = {
    buttonTextStartChapter: inputData.primary.button_text_start_chapter ?? '',
    buttonTextChapterOverview:
      inputData.primary.button_text_chapter_overview ?? '',
    buttonTextLinkToSequence:
      inputData.primary.button_text_link_to_sequence ?? '',
    placeholderImage: inputData?.primary?.placeholder_image?.url ?? '',
    closeMenuText: inputData.primary.close_menu_text ?? '',
    backToLandingText: inputData.primary.back_to_landing_text ?? '',
    knowledgeBaseLinkTitle: inputData.primary.knowledge_base_link_title ?? '',
    documentaryLinkTitle: inputData.primary.documentary_link_title ?? '',
    knowledgeBaseLinkDesc:
      ((inputData.primary.knowledge_base_link_desc &&
        PrismicDOM.RichText.asHtml(
          inputData.primary.knowledge_base_link_desc
        )) as string) ?? '',
    documentaryLinkDesc:
      ((inputData.primary.documentary_link_description &&
        PrismicDOM.RichText.asHtml(
          inputData.primary.documentary_link_description
        )) as string) ?? '',
    legalEntries: resultLegalEntries,
  }

  return result
}

// eslint-disable-next-line
export const parseThumbnail = (data: any) => {
  const result: Thumbnail = {
    url: '',
    alt: '',
    responsiveUrls: {},
  }
  const defaultFields = ['dimensions', 'alt', 'copyright', 'url']
  for (const resolution in data) {
    if (
      defaultFields.indexOf(resolution) < 0 &&
      typeof data[resolution] === 'object'
    ) {
      result.responsiveUrls[resolution] = {
        dimensions: data[resolution].dimensions,
        url: data[resolution].url,
      }
    }
  }
  result.url = data.url ?? ''
  result.alt = data.alt
  return result
}

export const parseChapter = (inputData: IPrismicChapter) => {
  const data = inputData?.data
  const result: IChapter = {
    id: inputData.id ?? '',
    uid: inputData.uid ?? '',
    type: 'chapter',
    idx: !(data.chapter_number ?? data.chapter_number === 0)
      ? 0
      : data.chapter_number > 0
      ? data.chapter_number
      : -1,
    localizations:
      inputData.alternate_languages.map((item) => {
        return {
          language: item.lang,
          uid: item.uid,
        }
      }) ?? [],
    title:
      ((data.chapter_title &&
        PrismicDOM.RichText.asText(data.chapter_title)) as string) ?? '',
    color: data.chapter_color ?? '',
    category: data.category ?? '',
    author:
      ((data.author && PrismicDOM.RichText.asHtml(data.author)) as string) ??
      '',
    leadText:
      ((data.lead && PrismicDOM.RichText.asHtml(data.lead)) as string) ?? '',
    outroText:
      ((data.next_chapter_preview_text &&
        PrismicDOM.RichText.asHtml(
          data.next_chapter_preview_text
        )) as string) ?? '',
    abstract:
      ((data.abstract &&
        PrismicDOM.RichText.asHtml(data.abstract)) as string) ?? '',
    keyText: data.seo_or_social_media_description_of_the_chapter ?? '',
    subTitle: data.subtitle ?? '',
    thumbnail: (data.thumbnail && parseThumbnail(data.thumbnail)) ?? null,

    backgroundImage: data.background_image?.url ?? '',
    worldImage: data.world_image?.url ?? '',
    worldMarker: data.world_marker?.url ?? '',
    lottieChapterTitleAnimation: data.lottie_chapter_title_animation?.url ?? '',
    introText:
      ((data.intro_text &&
        PrismicDOM.RichText.asText(data.intro_text)) as string) ?? '',
    nextChapterButtonText: data.next_chapter_button_text ?? '',
    background: data.background?.id ?? '',
    introVideoMobile: data.intro_video_mobile?.url ?? '',
    introVideoDesktop: data.intro_video_desktop?.url ?? '',
    subchaptersIds: data.subchapters
      ? data.subchapters.map(
          (subchapterData: { [id: string]: ISubchapter }) =>
            subchapterData.subchapter.id
        )
      : [],
    nextChapterBackground: data.next_chapter_background?.url ?? '',
    isFeaturedWithBackgroundImage:
      data.is_featured_with_background_image ?? false,
    isFeaturedWithGrayBackground:
      data.is_featured_with_gray_background ?? false,
    // Ranking is 1-3, so to show missing ranking use -1
    ranking: parseInt(data.ranking) ?? -1,
    isFeaturedOnTopBanner: data.is_featured_on_top_banner ?? false,
    publicationDate: data.date_of_publication ?? DateTime.local(),
    hubTeaserText:
      ((data.hub_teaser_text &&
        PrismicDOM.RichText.asHtml(data.hub_teaser_text)) as string) ?? '',
    relatedArticles: data.related_articles
      ? data.related_articles.map(
          (articleData) => articleData.related_article.id
        )
      : [],
    tags: data.tags ? data.tags.map((tagData) => tagData.tag) : [],
    noCover: data.no_cover ?? false,
    isScrollytellingChapter: data.is_scrollytelling_chapter ?? false,
    iconBeforeHeadline: data.icon_before_headline?.url ?? '',
    durationOfPodcast: data.duration_of_audio,
  }
  return result
}

export const parsePodcastSeries = (item?: IPodcastItemPrismicData) => {
  if (!item) {
    return
  }
  const newSerie: PodcastSerie = {
    title: item.primary.series_name,
    hubTitle: item.primary.series_title_hub ?? '',
    episodesName: item.primary.episodes_name ?? '',
    description: PrismicDOM.RichText.asHtml(item.primary.series_description),
    thumbnail: parseThumbnail(item.primary.series_image),
    // eslint-disable-next-line
    podcasts: item.items.map((podcastItem) => podcastItem.podcasts.id),
    ranking: parseInt(item.ranking) ?? -1,
    orderByName: item.primary.order_by_podcast_name ?? false,
  }
  return newSerie
}
