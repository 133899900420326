import { observable, action } from 'mobx'
import bind from 'bind-decorator'
import { setLocalStorageItem, getLocalStorageItem } from 'utils/localStorage'

interface ILocalStorage {
  wasClosedByUser: boolean
  closeDate: number
}

class IEBannerStore {
  @observable
  bannerIsShown: boolean

  constructor() {
    this.bannerIsShown = this.shouldShowBanner()
  }

  @bind
  shouldShowBanner() {
    const MS_IN_SECOND = 1000
    const SECONDS_IN_MINUTE = 60
    const MINUTES_IN_HOUR = 60
    const HOURS_IN_DAY = 24
    const DAYS_IN_WEEK = 7
    const MS_IN_WEEK =
      MS_IN_SECOND *
      SECONDS_IN_MINUTE *
      MINUTES_IN_HOUR *
      HOURS_IN_DAY *
      DAYS_IN_WEEK
    const parsedBannerData = getLocalStorageItem<ILocalStorage>('bannerData')
    if (!parsedBannerData) {
      return true
    }
    const { wasClosedByUser, closeDate } = parsedBannerData
    if (!wasClosedByUser) {
      return true
    }
    const timeDifference = Date.now() - closeDate
    if (timeDifference > MS_IN_WEEK) {
      return true
    }
    return false
  }

  @bind
  @action
  showBanner() {
    this.bannerIsShown = true
  }

  @bind
  @action
  hideBanner() {
    this.bannerIsShown = false
    const wasClosedByUser = true
    const closeDate = Date.now()
    const bannerData = {
      wasClosedByUser,
      closeDate,
    }
    setLocalStorageItem({ bannerData })
  }
}

export const ieBannerStore = new IEBannerStore()
