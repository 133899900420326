import { WithStyles, withStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { appConfig } from 'config'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import SliderButton from 'projects/carllutz/components/buttons/SliderNavigation'
import * as React from 'react'
import { breakpoints } from 'stores/breakpointsStore'
import { contentStore } from 'stores/contentStore/contentStore'
import { Chapter } from 'stores/contentStore/contentTypes'
import { FullscreenSubcontent } from 'stores/contentStore/subcontent_modules'
import { currentStory } from 'stores/currentStoryStore'
import { routerStore } from 'stores/routerStore'
import { sliderStore } from 'stores/sliderStore'
import { ComponentConstructor, ScrollPageChild } from '../ScrollPage'
import { ScrollSidebarStyles } from './scrollSideBar.style'

export interface SideBarProps {
  chapter?: Chapter
}

type StyleProps = WithStyles<typeof ScrollSidebarStyles>

@observer
class ScrollSidebarCls extends ScrollPageChild<SideBarProps & StyleProps> {
  @observable
  scrollPosition = 0
  @observable
  quizesAreActive = false
  @observable
  currentChapterBackgrounds: FullscreenSubcontent[] = []
  @observable
  chapterNumberHovered: boolean = false

  componentDidMount() {
    this.loadScrollerData()
  }

  componentDidUpdate() {
    this.loadScrollerData()
  }

  @bind
  @action
  mouseEnter() {
    if (sliderStore.currentSlideIdx !== 0) {
      this.chapterNumberHovered = true
    }
  }

  @bind
  @action
  mouseLeave() {
    if (sliderStore.currentSlideIdx !== 0) {
      this.chapterNumberHovered = false
    }
  }

  @bind
  loadScrollerData() {
    if (
      this.props.scrollerData &&
      this.props.chapter &&
      sliderStore.currentChapter?.uid === this.props.chapter.uid
    ) {
      const newScrollerData = Object.assign([], this.props.scrollerData)
      currentStory.setScrollerData(newScrollerData)
    }
  }

  @bind
  subchapterClick(id: string) {
    if (!appConfig.useScrollbarForNavigation) {
      return
    }
    const scrollEl = this.props.scrollerRefs?.get(id)
    if (this.props.scrollerRefs && scrollEl) {
      scrollEl.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'smooth',
      })
    }
  }

  toPreviousSlide() {
    const currentSlideIdx = sliderStore.currentSlideIdx
    const previousChapterUid =
      contentStore.chapters &&
      contentStore.chapters[currentSlideIdx - 1] &&
      contentStore.chapters[currentSlideIdx - 1].uid
    if (previousChapterUid) {
      routerStore.push(`/${appConfig.contentUnitUrl}/${previousChapterUid}`)
    }
  }

  @computed
  get currentSectionTitle() {
    let result = ''
    if (!currentStory.activeSectionId) {
      return result
    }
    const subchapter = contentStore.subchapters?.get(
      currentStory.activeSectionId
    )
    if (subchapter) {
      const title = subchapter.title
      result = title.replace(/\s*<br\s*\/>\s*/gm, ' ') // replace <br/> tags and possible surrounding spaces with one space
    }
    return result
  }

  render() {
    const { classes } = this.props

    const bars = []

    if (!this.props.chapter) {
      return null
    }

    let fillerHeight = 0

    for (let i = 0; i < this.props.chapter.subchaptersIds.length; i++) {
      const currentSubchapterId = this.props.chapter.subchaptersIds[i]
      const subchapter = contentStore.subchapters.get(currentSubchapterId)
      if (subchapter) {
        if (this.props.scrollerData) {
          const index = appConfig.noCover ? i : i + 1
          fillerHeight =
            Math.max(Math.min(this.props.scrollerData[index] ?? 0, 1.0), 0.0) *
            100
        }
        const mainSubcontent = contentStore.getSubcontentIfExists(
          subchapter.subcontentId
        )
        if (mainSubcontent?.shouldRender) {
          bars.push(
            <div
              key={subchapter.id}
              className={classnames(classes['bar-item'], {
                [classes['bar-item-active']]:
                  currentSubchapterId === currentStory.activeSectionId,
              })}
              onClick={() => this.subchapterClick(currentSubchapterId)}
              style={{
                cursor: appConfig.useScrollbarForNavigation ? 'pointer' : '',
              }}
            >
              <div
                className={classnames(
                  currentStory.sideBarIsShown
                    ? classes['section-title']
                    : classes['section-title-hidden'],
                  {
                    [classes['section-title-active']]:
                      currentSubchapterId === currentStory.activeSectionId,
                  }
                )}
                style={{
                  opacity: currentStory.sideBarIsShown ? 1 : 0,
                }}
              >
                {subchapter.title}
              </div>
              <div
                className={classnames(classes['bar-base'], {
                  [classes['bar-base-active']]:
                    currentSubchapterId === currentStory.activeSectionId,
                })}
              />
              <div
                className={classes.filler}
                style={{
                  height: `${fillerHeight}%`,
                  backgroundColor: this.props.chapter.color,
                }}
              ></div>
            </div>
          )
        }
      }
    }

    const chapter = sliderStore.currentChapter
    let chapterNumberElement: JSX.Element | null = null
    let chapterNumber = ''

    if (chapter?.idx) {
      chapterNumber = `0${chapter.idx}`
      chapterNumberElement = (
        <div
          className={classnames(
            {
              [classes['invisible']]: !currentStory.sideBarIsShown,
            },
            classes['navigation-button-container']
          )}
          onMouseLeave={this.mouseLeave}
          onMouseEnter={this.mouseEnter}
        >
          <div
            className={classnames(classes['chapter-number'], {
              [classes['invisible-on-hover']]: this.chapterNumberHovered,
            })}
            style={{
              backgroundColor: chapter.color,
            }}
          >
            {chapterNumber}
          </div>

          {breakpoints.desktop && sliderStore.currentSlideIdx !== 0 && (
            <SliderButton
              direction={'previous'}
              onClick={this.toPreviousSlide}
              hoverContainerClassName={classnames(
                classes['navigation-button'],
                {
                  [classes['navigation-button-invisible']]: !this
                    .chapterNumberHovered,
                }
              )}
            />
          )}
        </div>
      )
    }
    return (
      <React.Fragment>
        <div
          className={classnames(
            classes['scroll-sidebar'],
            {
              [classes.invisible]: currentStory.vertiefungExpanded,
            },
            {
              [classes['sidebar-is-shown']]: currentStory.sideBarIsShown,
            }
          )}
        >
          {!currentStory.vertiefungExpanded && currentStory.sideBarIsShown && (
            <div className={classes['left-dark-overlay']} />
          )}
          {chapterNumberElement}
          {bars}
          {breakpoints.desktop && (
            <div
              className={classnames({
                [classes['current-section-title']]: currentStory.sideBarIsShown,
              })}
              style={{
                opacity: currentStory.sideBarIsShown ? 1 : 0,
              }}
            >
              {this.currentSectionTitle}
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export const ScrollSidebar = withStyles(ScrollSidebarStyles, {
  name: 'ScrollSidebar',
})(ScrollSidebarCls) as ComponentConstructor<SideBarProps>
