import { observable, action } from 'mobx'
import bind from 'bind-decorator'
import { setLocalStorageItem, getLocalStorageItem } from 'utils/localStorage'
import { legalStore } from './legalStore'
import { menuStore } from './menuStore'

class CookiesBannerStore {
  @observable
  bannerIsShown: boolean

  @observable
  cookiesAllowed: boolean = false

  @observable
  cookiesInfoCallback(): void {
    if (!legalStore.showingLegal) {
      legalStore.toggleImpressum('Datenschutz')
      menuStore.openMenu()
    }
  }

  constructor() {
    this.cookiesAllowed = getLocalStorageItem('cookiesAreSet') ?? false
    this.bannerIsShown = this.shouldShowBanner()
  }

  @bind
  shouldShowBanner() {
    if (this.cookiesAllowed === false) {
      return true
    }
    return false
  }

  @bind
  @action
  showBanner() {
    this.bannerIsShown = true
  }

  @bind
  @action
  hideBanner() {
    this.bannerIsShown = false
  }

  @bind
  @action
  agreeAndHideBanner() {
    this.hideBanner()
    this.cookiesAllowed = true
    setLocalStorageItem({ cookiesAreSet: true })
  }
}

export const cookiesBannerStore = new CookiesBannerStore()
