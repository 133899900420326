import { WithStyles, withStyles } from '@material-ui/styles'
import { bind } from 'bind-decorator'
import { ButtonProp } from 'components/common/elements/buttons'
import { appConfig } from 'config'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { ModulesAndComponentsLoader } from 'modules/ModulesLoader'
import React from 'react'
import { breakpoints } from 'stores/breakpointsStore'
import { importFromProject } from 'utils/importHelper'
import { TopBarButtonsStyles } from './TopBarButtons.style'

type TopBarButtonsProps = ButtonProp & WithStyles<typeof TopBarButtonsStyles>

@observer
export class TopBarButtonsCls extends React.Component<TopBarButtonsProps> {
  buttons: Map<string, React.ComponentType> = observable.map(new Map(), {
    deep: false,
  })
  @observable
  projectComponent: JSX.Element | null | undefined = undefined

  async loadProjectComponent() {
    let module: JSX.Element | null = null
    const Component: React.ComponentType | null = await importFromProject(
      'components/TopBar/TopBarButtons'
    )
    if (Component) {
      module = <Component />
    }
    this.setProjectComponent(module)
  }

  @bind
  @action
  setProjectComponent(module: JSX.Element | null) {
    this.projectComponent = module
  }

  async componentDidMount() {
    await this.loadProjectComponent()
  }

  render() {
    if (this.projectComponent) return this.projectComponent
    const { classes } = this.props
    const buttons: JSX.Element[] = []
    const breakpoint = breakpoints.desktop ? 'desktop' : 'phone'
    for (const buttonName of appConfig.topBarButtons[breakpoint]) {
      buttons.push(
        <ModulesAndComponentsLoader
          moduleName={`buttons/topBar/${buttonName}`}
          isModule={false}
          key={`topBarButton_${buttonName}`}
        />
      )
    }
    return <div className={classes.container}>{buttons}</div>
  }
}

export default withStyles(TopBarButtonsStyles, { name: 'TopBarButtons' })(
  TopBarButtonsCls
)
