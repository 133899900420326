import { ContentStore } from 'stores/contentStore/contentStore'
// import { contentStore } from './contentStore'

export const contentStoreSingleton: { contentStore: ContentStore | null } = {
  contentStore: null,
}
export function getContentStore() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return contentStoreSingleton.contentStore
}
