import { windowStore } from '../../../stores/windowStore'
import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'
import { DESKTOP, MOBILE } from 'utils/styles'

export const ScrollSidebarStyles = (theme: ThemeType) =>
  createStyles({
    'bar-item': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1',
      position: 'relative',
      marginBottom: '10px',
      zIndex: 5,
      width: '100%',
    },
    'bar-base': {
      backgroundColor: theme.palette.white(0.7),
      position: 'absolute',
      height: '100%',
      right: 0,
      [`@media ${DESKTOP}`]: {
        width: '3px',
      },
      [`@media ${MOBILE}`]: {
        width: '2px',
      },
    },
    'text-container': {
      color: theme.palette.sideElements.desktop.fontColor(0.7),
      hyphens: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: 1.24,
      letterSpacing: '0.34px',
      textAlign: 'center',
      transform: 'rotate(180deg)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      fontSize: '0.85rem',
      '-ms-writing-mode': 'tb-lr',
      [`@media ${MOBILE}`]: {
        color: theme.palette.sideElements.phone.fontColor(0.7),
      },
    },
    '@keyframes glow-grow': {
      '0%': { opacity: 0, transform: 'scale(1)' },
      '80%': { opacity: 1 },
      '100%': { transform: 'scale(1.5)', opacity: 0 },
    },
    text: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: theme.fonts.primary.sansSerif,
    },
    'current-section-title': {
      fontFamily: theme.fonts.primary.sansSerif,
      fontSize: '0.85rem',
      position: 'absolute',
      top: theme.constants.sizes.desktop.topBarHeight,
      left: 0,
      bottom: theme.constants.sizes.desktop.bottomBarHeight,
      width: theme.constants.sizes.desktop.sideBarWidth,
      '-ms-writing-mode': 'tb-lr',
      transform: 'rotate(180deg)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '2rem',
      zIndex: 2,
      transition: 'opacity 0.6s 0.5s',
      writingMode: 'vertical-lr',
    },
    'current-section-text': {
      color: theme.palette.sideElements.desktop.fontColor(1.0),
      [`@media ${MOBILE}`]: {
        color: theme.palette.sideElements.phone.fontColor(1.0),
      },
    },
    'left-dark-overlay': {
      position: 'absolute',
      left: '-1px',
      width: '100px',
      height: `calc(100% - ${theme.constants.sizes.desktop.topBarHeight}px)`,
      top: theme.constants.sizes.desktop.topBarHeight,
      opacity: 0.35,
      zIndex: 1,
      backgroundImage: `linear-gradient(to right, ${theme.palette.black()}, ${theme.palette.black(
        0
      )})`,
      [`@media ${MOBILE}`]: {
        display: 'none',
      },
    },
    'scroll-sidebar': {
      height: 0,
      color: theme.palette.sideElements.desktop.fontColor(1.0),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 600,
      opacity: 1,
      transform: 'translate(0, 0)',
      transition: 'all 0.6s',
      willChange: 'transform',
      position: 'sticky',
      '-webkit-backface-visibility': 'hidden',
      top: 0,
      left: 0,
      [`@media ${DESKTOP}`]: {
        paddingTop: theme.constants.sizes.desktop.topBarHeight,
        paddingBottom: theme.constants.sizes.desktop.bottomBarHeight,
        width: theme.constants.sizes.desktop.sideBarWidth,
      },
      [`@media ${MOBILE}`]: {
        color: theme.palette.sideElements.phone.fontColor(1.0),
        width: theme.constants.sizes.phone.sideBarWidth,
        paddingTop: theme.constants.sizes.phone.topBarHeight,
      },
    },
    'sidebar-is-shown': {
      height: windowStore.height,
    },
    filler: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      [`@media ${DESKTOP}`]: {
        width: '3px',
      },
      [`@media ${MOBILE}`]: {
        width: '2px',
      },
      '& $circle-end': {},
    },
    'circle-end': {},
    invisible: {
      opacity: 0,
      transform: 'translate(-200vw, 0)',
      transition: 'all 0.6s',
    },
    'chapter-number': {
      width: theme.constants.sizes.desktop.topBarHeight,
      height: theme.constants.sizes.desktop.topBarHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.fonts.primary.serif,
      fontSize: '0.9rem',
      lineHeight: 1.12,
      letterSpacing: '-0.66px',
      textAlign: 'center',
      color: theme.palette.black(),
      position: 'relative',
      [`@media ${MOBILE}`]: {
        width: 25,
        height: 25,
        fontFamily: theme.fonts.primary.serif,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: 1.31,
        letterSpacing: 0.39,
        color: theme.palette.black(),
        position: 'relative',
      },
    },
    'navigation-button-container': {
      display: 'none',
      width: theme.constants.sizes.desktop.topBarHeight,
      height: theme.constants.sizes.desktop.topBarHeight,
      position: 'relative',
      zIndex: 5,
      cursor: 'pointer',
      opacity: 1,
      transition: 'opacity 0.3s',
      [`@media ${MOBILE}`]: {
        width: 25,
        height: 25,
        position: 'relative',
        top: theme.constants.sizes.phone.topBarHeight,
        left: theme.constants.sizes.phone.topBarHeight - 10,
      },
    },
    'invisible-on-hover': {},
    'navigation-button': {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'none',
    },
    'navigation-button-invisible': {
      opacity: 0,
      transition: 'opacity 0.4s cubic-bezier(0.5, 1, 0.89, 1)',
    },
    'section-title-hidden': {
      height: 0,
    },
    'section-title': {
      display: 'none',
      writingMode: 'vertical-lr',
    },
    'bar-base-active': {},
    'section-title-active': {},
    'bar-item-active': {},
  })
