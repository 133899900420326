export function replaceFilename(
  path: string | null | undefined,
  newFile: string
) {
  if (path === null || path === undefined) {
    return newFile
  }
  const lastSlash = path.lastIndexOf('/')
  if (lastSlash > 0) {
    return path.substr(0, lastSlash) + '/' + newFile
  } else {
    return newFile
  }
}

export const cropStringToLength = (input: string, length: number) => {
  if (!input) {
    return ''
  } else if (input.length <= length) {
    return input
  } else {
    return input.substr(0, length) + '...'
  }
}

export const cropChapterNumber = (title: string) => {
  const dashRegex = /Chapter \d\s*-\s*(.+)/gi
  const titleMatch = dashRegex.exec(title)
  if (titleMatch?.[1]) {
    return titleMatch[1]
  }
  return title
}

// Inline element title has form 'Inline element type - Chapter 1.2.3 - Name of the element'
// Trim first two non-informative parts and keep only Name of the element
export const beautifySubcontentTitle = (text: string) => {
  const textFragments = text.split('-')
  if (textFragments.length < 3) {
    return text
  }
  const trimmedName = textFragments.slice(2).join('-').trim()
  return capitalizeString(trimmedName)
}

// Subchapters have name of the form 'Subchapter 1.2.3 - Name of the subchapter'.
// Trim subchapter number and keep only the name
export const beautifySubchapterTitle = (text: string) => {
  const textFragments = text.split('-')
  if (textFragments.length < 2) {
    return text
  }
  const trimmedName = textFragments.slice(1).join('-').trim()
  return capitalizeString(trimmedName)
}

export const cutSubcontentType = (type: string) => {
  // Generally, fullscreen subcontent type coming from prismic has form fullscreen_subcontent_image
  // so we crop the first to words as unnecessary
  const typeArr = type.split('_')
  if (!typeArr.length) {
    return type
  }
  return typeArr[typeArr.length - 1]
}

export const beautifyWorksheetTitle = (title: string) => {
  const titleRegex = /ARBEITSBLATT KAPITEL [0-9]:\s+/gi
  return title.replace(titleRegex, '')
}

export const capitalizeString = (text: string) => {
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

export const convertTimeStringToSeconds = (duration?: string) => {
  if (!duration) {
    return undefined
  }
  const timeRegex = /(\d+):(\d+)/gi
  const match = timeRegex.exec(duration)
  if (match?.[1] && match[2]) {
    const minutes = parseInt(match[1], 10)
    const seconds = parseInt(match[2], 10)
    return minutes * 60 + seconds
  }
  return 0
}

export const convetSecondsToString = (time: number) => {
  const seconds = Math.floor(time % 60)
  const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`
  const minutes = Math.floor((time / 60) % 60)
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  return `${minutesString} : ${secondsString}`
}

export const camelify = (input: string, divider: string = '_') => {
  return input
    .split(divider)
    .map((el: string, id: number) => {
      if (id !== 0) {
        return capitalizeString(el)
      }
      return el
    })
    .join('')
}

export const encodeParagraphs = (input: string) => {
  const encodedString = input
    .replace(/<\/p><p>/g, '\n')
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '')
  return encodeURIComponent(encodedString)
}

const shyDashRegex = /\?-\?/g
export const replaceRegexWithShyDashes = (str: string) => {
  return str.replace(shyDashRegex, '\u00AD')
}
