import { Chapter, IChapter } from 'stores/contentStore/contentTypes'
import { defaultTheme, ThemeType } from 'theme'
import escherTheme from 'projects/escher/theme'
import { importFromProject } from 'utils/importHelper'

export function getAppConfig<T = Record<string, unknown>>(): T & ConfigType {
  return appConfig as T & ConfigType
}

export interface ILanguageType {
  code: string
  name: string
}

interface StickyBackgrounds {
  desktop: boolean
  phone: boolean
}

export interface ExtraRoute {
  path: string
  component: string
}

export interface VideoCustomizationConfig {
  showMeta?: boolean
}

export interface ConfigType {
  prismicPath: string
  showLegalInsideMenu: boolean
  modules: string[]
  languages: ILanguageType[]
  tabsAreClosable: boolean
  showThumbnailInFullscreenOnMobile: boolean
  useScrollbarForNavigation: boolean
  backgroundsAreSticky: StickyBackgrounds
  // For cases when text content is empty(like scrollytelling or Novartis phtostory)
  stickyPositionExceptions: string[]
  sectionTextBoxWidth: number
  topBarButtons: {
    desktop: string[]
    phone: string[]
  }
  landingPageRoute: string
  notFoundRoute: string
  stickyBackgroundHasFixedWidth: boolean
  prismicModelsNamesMap: {
    page: string
    section: string
    legal: string
    impressumSlug: string
    quellenSlug: string
    dataPrivacySlug: string
    subcontentNamesArray: string[]
  }
  legalSections: string[]
  contentUnitUrl: string
  noNextChapterPreview: boolean
  noCover: boolean
  chaptersSortingFunction(a: IChapter, b: IChapter): number
  extraRoutes: ExtraRoute[]
  useFallbackBackgrounds: boolean
  touchSwipeToSwitchChaptersDisabled: 'all' | 'none' | 'scrolly' //disables the feature to swipe between chapters on touch devices. 'all': on all chapters, 'none': off - default, 'scrolly': only on chapters with scrollytelling subcontents
  videoCustomization: VideoCustomizationConfig
  isVideobook: boolean
}

const defaultConfig: ConfigType = {
  prismicPath: '',
  showLegalInsideMenu: false,
  modules: [] as string[],
  languages: [
    {
      code: 'de-ch',
      name: 'Deutsch',
    },
  ],
  tabsAreClosable: false,
  showThumbnailInFullscreenOnMobile: false,
  useScrollbarForNavigation: true,
  backgroundsAreSticky: {
    desktop: true,
    phone: true,
  },
  stickyPositionExceptions: ['scrollytelling'],
  sectionTextBoxWidth: 600,
  topBarButtons: {
    desktop: [],
    phone: [],
  },
  landingPageRoute: '/',
  notFoundRoute: '/',
  stickyBackgroundHasFixedWidth: false,
  prismicModelsNamesMap: {
    page: 'chapter',
    section: 'subchapter',
    legal: 'imprint-sources-rights',
    impressumSlug: 'impressum',
    quellenSlug: 'text--und-bildquellenverzeichnis',
    dataPrivacySlug: 'datenschutz',
    subcontentNamesArray: [
      'fullscreen_subcontent_video',
      'fullscreen_subcontent_image',
      'fullscreen_subcontent_text',
      'fullscreen_subcontent_gallery',
      'fullscreen_subcontent_graphics',
      'fullscreen_subcontent_scrollytelling',
    ],
  },
  legalSections: ['Impressum', 'Datenschutz', 'Quellen'],
  contentUnitUrl: 'chapter',
  noNextChapterPreview: false,
  noCover: false,
  chaptersSortingFunction: (ch1: Chapter, ch2: Chapter) => ch1.idx - ch2.idx,
  extraRoutes: [],
  useFallbackBackgrounds: false,
  touchSwipeToSwitchChaptersDisabled: 'none',
  isVideobook: true,
  videoCustomization: {},
}

export const defaultModules: string[] = ['Menu', 'Main', 'DocFinder']

export const appConfig: ConfigType = defaultConfig

export const fullTheme: ThemeType = defaultTheme

export async function loadConfig() {
  const config: ConfigType | null = await importFromProject('config.ts')
  if (config) {
    Object.assign(appConfig, config)
  }

  const projectTheme: ThemeType | null = await importFromProject('theme.ts')
  if (projectTheme) {
    Object.assign(fullTheme, projectTheme)
  } else {
    // For secondary projects like Dufour, Stauffacher, etc. use Escher theme
    Object.assign(fullTheme, escherTheme)
  }
}
