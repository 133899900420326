import { WithStyles, withStyles } from '@material-ui/styles'
import bind from 'bind-decorator'
import classnames from 'classnames'
import { action, autorun, IReactionDisposer, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { sliderStore } from 'stores/sliderStore'
import { safeHtml } from 'utils/validation'
import { contentStore } from 'stores/contentStore/contentStore'
import InlineSubcontentDivider from 'stores/contentStore/sectionItems/divider/InlineSubcontentDivider'
import { ieBannerStore } from 'stores/ieBannerStore'
import { isIE } from 'utils/platform'
import closeIcon from 'components/icons/crossBlack.svg'
import { StandardButton } from '../elements/buttons'
import { BannerOverlay } from './BannerOverlay'
import { IEBannerStyles } from './iebanner.style'

type StyleProps = WithStyles<typeof IEBannerStyles>

type IIEBannerProps = StyleProps

@observer
export class IEBannerCls extends React.Component<IIEBannerProps> {
  @observable
  shouldRender: boolean = false

  disposer: IReactionDisposer

  constructor(a: IIEBannerProps) {
    super(a)
    this.disposer = autorun(() => {
      if (sliderStore.showContent) {
        window.setTimeout(() => this.setRender(), 3000)
      }
    })
  }

  componentWillUnmount() {
    this.disposer()
  }

  @bind
  @action
  setRender() {
    this.shouldRender = true
  }

  render() {
    const { classes } = this.props
    let header = 'Default header'
    let logo = ''
    let textGerman = ''
    let textEnglish = 'Outdated IE browser'
    if (contentStore.uiElements?.ieBanner) {
      header = contentStore.uiElements.ieBanner.header
      logo = contentStore.uiElements.ieBanner.logo
      textGerman = contentStore.uiElements.ieBanner.textGerman
      textEnglish = contentStore.uiElements.ieBanner.textEnglish
    }
    return (
      (isIE && ieBannerStore.bannerIsShown && this.shouldRender && (
        <BannerOverlay>
          <div className={classes.container}>
            <div className={classes['inner-container']}>
              <div className={classes['close-button-container']}>
                <StandardButton
                  hoverContainerClassName={classes['button-hover-container']}
                  className={classes['close-button']}
                  onClick={ieBannerStore.hideBanner}
                >
                  <img
                    src={closeIcon}
                    className={classes['close-icon']}
                    alt="Close icon"
                  />
                </StandardButton>
              </div>
              <div className={classes.header}>
                {logo && (
                  <div className={classes['logo-container']}>
                    <img src={logo} alt="IE logo" className={classes.logo} />
                  </div>
                )}
                <div className={classes.title}>{header}</div>
              </div>
              <div className={classes.content}>
                <div
                  className={classnames(classes.text, classes['text-german'])}
                  {...safeHtml(textGerman)}
                ></div>
                <div className={classes['divider-container']}>
                  <InlineSubcontentDivider type={'Line'} color={'dark'} />
                </div>
                <div
                  className={classnames(classes.text, classes['text-english'])}
                  {...safeHtml(textEnglish)}
                ></div>
              </div>
            </div>
          </div>
        </BannerOverlay>
      )) ||
      null
    )
  }
}

export const IEBanner = withStyles(IEBannerStyles, { name: 'IEBanner' })(
  IEBannerCls
)
