import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'
import { DESKTOP, MOBILE } from 'utils/styles'

export const ChaptersBarStyles = (theme: ThemeType) =>
  createStyles({
    bar: {
      width: '100%',
      height: '2px',
      backgroundColor: theme.palette.white(0.7),
      marginBottom: '19.5px',
    },
    'bar-item': {
      color: theme.palette.sideElements.desktop.fontColor(0.7),
      marginRight: '8px',
      flex: 1,
      height: theme.constants.sizes.desktop.bottomBarHeight,
      fontSize: '1.2rem',
      lineHeight: 1.22,
      textAlign: 'left',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.white(0.3),
      },
      [`@media ${MOBILE}`]: {
        color: theme.palette.sideElements.phone.fontColor(0.7),
      },
    },
    'bar-chapter-number': {},
    'bar-chapter-number-styles': {
      marginLeft: '1rem',
    },
    'chapter-bar': {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      [`@media ${DESKTOP}`]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: theme.constants.sizes.desktop.bottomBarHeight,
      },
      [`@media ${MOBILE}`]: {
        flexDirection: 'column',
      },
    },
    text: {
      fontSize: '0.85rem',
      fontFamily: theme.fonts.primary.sansSerif,
      display: 'flex',
      alignItems: 'flex-start',
    },
    'title-text': {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    'active-text': {
      color: theme.palette.sideElements.desktop.fontColor(1.0),
      [`@media ${MOBILE}`]: {
        color: theme.palette.sideElements.phone.fontColor(1.0),
      },
    },
    'chapter-idx': {
      padding: '0 7px 0 5px',
      fontFamily: theme.fonts.secondary.serif,
      fontSize: '1.5rem',
      position: 'relative',
      top: '-0.4rem',
    },
    'chapter-idx-zero': {},
    open: {
      opacity: 1,
      zIndex: 10,
      willChange: 'transform',
      transform: 'translate3D(0, 0, 0)',
      '-webkit-backface-visibility': 'hidden',
      transition:
        'transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s, opacity 0s',
    },
    closed: {
      opacity: 0,
      transform: 'translate3D(0, 200%, 0)',
      transition:
        'transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0s 1s',
    },
    'only-show-current-chapter': {},
    'active-bar-item': {},
  })
