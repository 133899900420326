import { createStyles } from '@material-ui/styles'
import { ThemeType } from 'theme'

export const InlineSubcontentDividerStyles = (theme: ThemeType) =>
  createStyles({
    container: {
      padding: '0',
      boxSizing: 'border-box',
      width: '100%',
      marginBottom: 8,
      marginTop: 26,
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    'divider-left': {
      height: 0,
      width: 'calc(50% - 14px)',
      borderTop: `1px solid ${theme.palette.white(0.9)}`,
    },
    'divider-right': {
      height: 0,
      width: 'calc(50% - 14px)',
      borderTop: `1px solid ${theme.palette.white(0.9)}`,
    },
    'divider-middle': {
      width: '8px',
      height: '8px',
      margin: '5px',
      background: theme.palette.white(0.9),
      position: 'relative',
      top: '-8px',
      transform: 'rotateZ(45deg)',
    },
    light: {
      background: theme.palette.black(0.3),
    },
    'line-light': {
      borderTop: `1px solid ${theme.palette.black(0.3)}`,
    },
    dark: {
      background: theme.palette.black(0.9),
    },
    'line-dark': {
      borderTop: `1px solid ${theme.palette.black(0.9)}`,
    },
  })
